import './Perfil.css'; // Importa el archivo de estilos CSS
import { get_profile, delete_user, patch_user, change_password } from '../../api';
import { update_cv ,get_paises, get_industrias, get_profesiones, get_universidades, get_cargos, 
  get_anos_experiencia, get_disponibilidades, get_jornadas, get_modalidades, get_idiomas, get_regiones,
   get_conocio_wot, get_formularios, get_areas, get_competencias, get_communities, join_community, get_cv, get_recomendation_comunities} from '../../api';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Modal from 'react-modal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import ajustes1 from "./imagenes/ajustes 1.png";
import imagen_porcompletar from "./imagenes/imagen_completar_datos.png";
import mujer2 from "./imagenes/mujer 2.png";
import imagen1 from "./imagenes/imagen 1.png";
import BotonModal from "./BotonModal";
import Chip from '@mui/material/Chip';
import { Box } from '@mui/system';
import imagen_perfil from "./imagenes/perfil_logo.png"
import Menu from '@mui/material/Menu';
import { JoinComunityAlert, ErrorJoinComunityAlert} from './Alerts';
import { validate, clean, format } from 'rut.js';
import codigosTelefonicos from './Codigos';

function Perfil() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [modalContraIsOpen, setModalContraIsOpen] = useState(false);
  const [nuevosDatos, setNuevosDatos] = useState({});
  const [datosPaises, setDatosPaises] = useState([]);
  const [datosIndustrias, setDatosIndustrias] = useState([]);
  const [datosProfesiones, setDatosProfesiones] = useState([]);
  const [datosAnios, setDatosAnios] = useState([]);
  const [datosUniversidades, setDatosUniversidades] = useState([]);
  const [datosCargos, setDatosCargos] = useState([]);
  const [datosDisponibilidad, setDatosDisponibilidad] = useState([]);
  const [datosJornada, setDatosJornada] = useState([]);
  const [datosModalidades, setDatosModalidades] = useState([]);
  const [datosIdiomas, setDatosIdiomas] = useState([]);
  const [datosCompromiso, setDatosCompromiso] = useState([]);
  const [datosWot, setDatosWot] = useState([]);
  const [datosPersonalidad, setDatosPersonalidad] = useState([]);
  const [datosAreas, setDatosAreas] = useState([]);
  const [datosCompetencia, setDatosCompetencias] = useState([]);
  const [mostrarCuadroTextoUniversidad, setMostrarCuadroTextoUniversidad] = useState(false);
  const [mostrarCuadroTextoPostgrado, setMostrarCuadroTextoPostgrado] = useState(false);
  const [mostrarPueblo, setMostrarPueblo] = useState(false);
  const [mostrarCargoAdicional, setMostrarCargoAdicional] = useState(false);
  const [mostrarFactor, setMostrarFactor] = useState(false);
  const [mostrarContacto1, setMostrarContacto1] = useState(false);
  const [mostrarContacto2, setMostrarContacto2] = useState(false);
  const [mostrarCompromiso, setMostrarCompromiso] = useState(false);
  Modal.setAppElement('#root');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [datos, setDatos] = useState([]);
  const [profesion1,setProfesion1] = useState('');
  const [profesion2,setProfesion2] = useState('');
  const [contacto1, setContacto1] = useState([]);
  const [contacto2, setContacto2] = useState([]);
  const [datosForm,setDatosForm] = useState({
    regionActualDomicilio: {id: ''},
  });

  const [activeInfo, setActiveInfo] = useState(1);
  const [file, setFile] = useState(null);
  const [porcentajeProgress, setPorcentajeProgress] = useState(0);
  const [selectedPostgrado,setSelectedPostgrado] = useState('No');
  const [selectedFactor,setSelectedFactor] = useState('');
  const [selectedCompromiso,setSelectedCompromiso] = useState("No");
  const [selectedPueblo,setSelectedPueblo] = useState('No');
  const [stringDisponibilidad,setStringDisponibilidad] = useState('')
  const [stringCompetencias,setStringCompetencias] = useState('')
  const [stringIndustrias,setStringIndustrias] = useState('')
  const [stringAreas,setStringAreas] = useState('')
  const [loadingPerfil, setLoadingPerfil] = useState(false);
  const [cv,setCv] = useState("");
  const [mostrarRegion, setMostrarRegion] = useState(true);
  const [rutValido, setRutValido] = useState(true);
  const [celValido, setCelValido] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const [codigoTelefonico, setCodigoTelefonico] = useState('');

  const [menuStatesAbrirSubMenu, setMenuStatesAbrirSubMenu] = useState({});

  const obtener_recomendacion_comunidades = async ()  =>{
    const token = Cookies.get("jwtToken");
    const comunidades = await get_recomendation_comunities(token);
    Cookies.set("recomendaciones", JSON.stringify(comunidades) ,{expires:1});
  }
 
  const isFieldEmpty = (value) => {
  
    if(value === '' || value === null || value === undefined || value.length === 0){
        return true;
    }
    return false;
  };

  const handleClickAbrirSubMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMenuStatesAbrirSubMenu((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  
  const [modalAnchorEl, setModalAnchorEl] = useState(null);  
  const [menuStatesAbrirModal, setMenuStatesAbrirModal] = useState({});
  
  const handleClickAbrirModal = (event, id) => {
    OpenModal();
    setModalIsOpen(false);
    setModalAnchorEl(event.currentTarget); 
    setMenuStatesAbrirModal((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    setMenuStatesAbrirSubMenu({});
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setModalAnchorEl(null); 
    setMenuStatesAbrirSubMenu({});
    setMenuStatesAbrirModal({});
  };
  

  const handleInfoChange = (infoNumber) => {
    setActiveInfo(infoNumber);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    //console.log(selectedFile);
    setFile(selectedFile);
  };
  const handleFile = async (event) => {
    alert('Se está subiendo el archivo, espere unos segundos antes de continuar');
    event.preventDefault();
    var formData = new FormData();
    formData.append('pdf', file);
    for (var key of formData.entries()) {
			//console.log(key[0] + ', ' + key[1])
		}
    const jwtToken = Cookies.get('jwtToken')
    //console.log(formData);
    const response =  await update_cv(jwtToken, formData);
    //console.log(response);
    //console.log(responseJson);
    if (response.status === 200){
      window.alert('Se ha subido el archivo correctamente');
    }
    else{
      window.alert('Ha ocurrido un error');
    }
  };

  const porcentaje = (datos) => {
    const camposObligatorios = [
      'nombre',
      'apellido',
      'rut',
      'celular',
      'mail',
      'paisDomicilio',
      'intereses',
      'profesion',
      'universidad',
      'postgrado',
      'cargo',
      'aniosExperiencia',
      'experienciaDirectorios',
      'altaDireccion',
      'areas',
      'competencia',
      'disponibilidad',
      'tipoJornada',
      'posibilidadCambiarseRegion',
      'tipoModalidad',
      'disposicion_viajar',
      'conocioWOT',
    ];
  
    let camposCompletados = 0;
  
    for (const campo of camposObligatorios) {
      if (campo in datos && datos[campo] !== null && datos[campo] !== undefined) {
        if (Array.isArray(datos[campo]) && datos[campo].length === 0) {
          continue; // Ignorar campos de arrays vacíos
        }
        camposCompletados++;
        //console.log(`Campo completado: ${campo}`);
      }
    }
  
    const progreso = (camposCompletados / camposObligatorios.length) * 100;
    setPorcentajeProgress(progreso);
  };  

  const navigate = useNavigate();
  
  const claveMapeo = {
    nombre: 'name',
    apellido: 'lastName',
  };

  function validarRut(rut) {
    try {
      if (!rut || typeof rut !== 'string' || rut.trim() === '') {
        //console.log('Error: RUT vacío o no es una cadena.');
        return false;
      }
      // Limpiar el RUT
      const rutLimpio = clean(rut);
      // Validar el RUT
      const esValido = validate(rutLimpio);
      //console.log('¿Es RUT válido?', esValido);
      return esValido;
    } catch (error) {
      console.error('Error en validarRut:', error.message);
      return false;
    }
  }  
  
  const handleInputChange = (event) => {
    try {
      const { name, value } = event.target;
      const nuevaClave = claveMapeo[name] || name;
      setDatos({ ...datos, [name]: value });
  
      // Validar el RUT 
      if (name === "rut") {
        const esRutValido = validarRut(value);
        setRutValido(esRutValido);
        //console.log('Estado de rutValido:', esRutValido);
      }
      
      if (name === "celular") {
        setCelValido(false);
        if (datosForm.id_pais_domicilio === 1 || datosForm.id_pais_domicilio === '1') {
            const esCelValido = /^9\d{8}$/.test(value);
            setCelValido(esCelValido);
        } else {
            setCelValido(true);
        }
    }
  
      setDatosForm({ ...datosForm, [name]: value });
      setNuevosDatos({ ...nuevosDatos, [nuevaClave]: value });
    } catch (error) {
      console.error('Error en handleInputChange:', error.message);
    }
  };
  //SE USA PARA LAS DECLARACIONES 
  function handleCheckboxChange(event) {
    const isChecked = event.target.checked;
    const { name, value } = event.target;
    setDatos({ ...datos, [name]: isChecked });
    setDatosForm({ ...datosForm, [name]: isChecked });
    setNuevosDatos({ ...nuevosDatos, [name]: isChecked })
  }
  //para cambiar de pag el formulario
  const handleNext =  async () => {
    if (currentStep<9){
      setCurrentStep(currentStep + 1);
      const datosActualizados = { ...nuevosDatos };
      for (const key in datosActualizados) {
        if (datosActualizados[key] === '') {
          datosActualizados[key] = null;
        }
      }
      const profesiones = [];
      if (profesion1 !== ''){
        profesiones.push(profesion1);
      }
      if (profesion2 !== ''){
        profesiones.push(profesion2);
      }
      if (profesiones.length !== 0){
        datosActualizados["profesiones"] = profesiones;
      }
      const contactos = [];
      if (mostrarContacto1){
        if (contacto1[0] !=='' || contacto1[1] !==''  || contacto1[2] !=='' ){
          contactos.push(contacto1);
        }}
      if (mostrarContacto2){
        if (contacto2[0] !=='' || contacto2[1] !==''  || contacto2[2] !=='' ){
          contactos.push(contacto2);
        }}
        if (contactos.length !==0){
          datosActualizados["contactos"] = contactos;
        }
      //console.log('Enviando datos actualizados:', datosActualizados);
      
      const token = Cookies.get('jwtToken');
      const responsePatch = await patch_user(token,datosActualizados);
      const response = await get_profile(token);
      //console.log('datoss', response);
      const responseData = await response.json();
      if (!("rut" in datosActualizados) && datos.rut === null){
        datosActualizados["rut"] = null;
      }
      else if (!("rut" in datosActualizados) && datos.rut !== null){
        datosActualizados["rut"] = datos.rut;
      }
      //datosActualizados["rut"] = responseData.rut;
      setDatos(responseData);
      setNuevosDatos({});
  } else{
    window.alert("Ha finalizado el formulario");
  }}
  //manejar el atras del formulario
  const handlePrev = async () => {
    if (currentStep-1 > 0){
      setCurrentStep(currentStep -1);
      const datosActualizados = { ...nuevosDatos };
      for (const key in datosActualizados) {
        if (datosActualizados[key] === '') {
          datosActualizados[key] = null;
        }
      }
      const profesiones = [];
      if (profesion1 !== ''){
        profesiones.push(profesion1);
      }
      if (profesion2 !== ''){
        profesiones.push(profesion2);
      }
      if (profesiones.length !== 0){
        datosActualizados["profesiones"] = profesiones;
      }

      const contactos = [];

      if (mostrarContacto1){
        if (contacto1[0] !=='' || contacto1[1] !==''  || contacto1[2] !=='' ){
          contactos.push(contacto1);
        }}
      if (mostrarContacto2){
        if (contacto2[0] !=='' || contacto2[1] !==''  || contacto2[2] !=='' ){
          contactos.push(contacto2);
        }}
        if (contactos.length !==0){
          datosActualizados["contactos"] = contactos;
        }

      const token = Cookies.get('jwtToken');
      const responsePatch = await patch_user(token,datosActualizados);
      const response = await get_profile(token);
      const responseData = await response.json();
      if (!("rut" in datosActualizados) && datos.rut === null){
        datosActualizados["rut"] = null;
      }
      else if (!("rut" in datosActualizados) && datos.rut !== null){
        datosActualizados["rut"] = datos.rut;
      }

      setDatos(responseData);
      setNuevosDatos({});
    } else {
      window.alert("No se puede ir mas atras");
    }};


    //handle select
    const handleDropdownChange = (event) => {
      const { name, value } = event.target;
      setDatosForm({ ...datosForm, [name]: value });
      setNuevosDatos({ ...nuevosDatos, [name]: value });
      if (name === 'id_pais_domicilio') {
        const paisSeleccionado = datosPaises.find((pais) => pais.id === parseInt(value, 10));
        if (paisSeleccionado) {
          let codigoTelefonico = '';
          
          if (paisSeleccionado.id === 1) {
            codigoTelefonico = '+56';
          } else {
            const codigoAsociado = codigosTelefonicos.find((codigo) => codigo.nombre === paisSeleccionado.pais);
            codigoTelefonico = codigoAsociado ? codigoAsociado.codigo : '';
          }
          setCodigoTelefonico(codigoTelefonico);
        }
      }
    };

    const handleDropdownChangeRegion = (event) => {
      const { name, value } = event.target;
    
      setDatosForm(prevState => ({
        ...prevState,
        regionActualDomicilio: {
          ...prevState.regionActualDomicilio,
          id: value
        }
      }));
    
      setNuevosDatos({ ...nuevosDatos, [name]: value });
    };
    
    
  //maneja lo escrito en el input
  const handleAdicional = (event) =>{
    const { name, value } = event.target;
    if (name==="rolAdicional" && value==="1"){
      setMostrarCargoAdicional(true);
    }
    else if(name === "postgrado"){
      if (value==="No"){
        setDatosForm({...datosForm,[name]: value});
        setNuevosDatos({ ...nuevosDatos, [name]: value });
        setSelectedPostgrado('No');
        setMostrarCuadroTextoPostgrado(false)
      }
      else{
        setMostrarCuadroTextoPostgrado(true)
        setSelectedPostgrado('Si');
      }
    }
    else if(name==="rolAdicional" && value===""){
      setMostrarCargoAdicional(false);
    }
    else if (name==="factor" && value==="Si"){
      setMostrarFactor(true);
      setSelectedFactor('Si')
    }
    else if(name==="factor" && value==="No"){
      setMostrarFactor(false);
      setSelectedFactor('No');
      setDatosForm({...datosForm,[name]: value});
      setNuevosDatos({ ...nuevosDatos, [name]: value });
    }
    else if (name==="compromiso" && value==="Si"){
        setMostrarCompromiso(true);
        setSelectedCompromiso("Si");
      }
      else if(name==="compromiso" && value==="No"){
        setMostrarCompromiso(false);
        setSelectedCompromiso("No");
    }
    else if (name==="pueblo" && value==="Si"){
      setMostrarPueblo(true);
      setSelectedPueblo('Si');
      
    }
    else if(name==="pueblo" && value==="No"){
      setMostrarPueblo(false);
      setSelectedPueblo('No');
      setDatosForm({...datosForm,['nombrePuebloOriginario']: value});
      setNuevosDatos({ ...nuevosDatos, ['nombrePuebloOriginario']: value });
    }
    else if (name==="contacto1" && value=='' ){
      setMostrarContacto1(false);

    }
    else if (name==="contacto1" && value==='1'){
      setMostrarContacto1(true);
      setContacto1(['','','']);
    }
    else if (name==="contacto2" && value==''){
      setMostrarContacto2(false);
    }
    else if (name==="contacto2" && value==='1'){
      setMostrarContacto2(true);
      setContacto2(['','','']);
    }

    };

  const handleArray = (event) => {
    const { name, value } = event.target;
  
    if (name === "idiomas") {
      // Manejar la selección múltiple para "idiomas"
      const valueArray = Array.isArray(value) ? value : [value];
      setDatosForm({ ...datosForm, [name]: valueArray });
      setNuevosDatos({ ...nuevosDatos, [name]: valueArray });
    } else if (name === "profesion1") {
      // Manejar la selección simple para "profesion1"
      setDatosForm({ ...datosForm, [name]: value });
      setProfesion1(value);
      //console.log(profesion1);
    } else if (name === "profesion2") {
      // Manejar la selección simple para "profesion2"
      setDatosForm({ ...datosForm, [name]: value });
      setProfesion2(value);
    } else if (name === "disponibilidad") {
      // Manejar la selección múltiple para "disponibilidad"
      const valueArray = Array.isArray(value) ? value : [value];
      setDatosForm((prevDatos) => ({
        ...prevDatos,
        [name]: Array.isArray(value) ? value : [value], // Asegurar que sea un arreglo simple
      }));
    
      setNuevosDatos((prevNuevosDatos) => ({
        ...prevNuevosDatos,
        [name]: Array.isArray(value) ? value : [value], // Asegurar que sea un arreglo simple
      }));
      //console.log("Valor actual de disponibilidad:", datosForm.disponibilidad);
    }
  };
//reduce transforma a objeto diccionario
  const diccionarioIndustrias = datosIndustrias.reduce((acc, obj) => {
    acc[obj.id] = obj.nombre_industria;
    return acc;
  }, {});
  const diccionarioAreas = datosAreas.reduce((acc, obj) => {
    acc[obj.id] = obj.nombre;
    return acc;
  }, {});
  const diccionarioCompetencias = datosCompetencia.reduce((acc, obj) => {
    acc[obj.id] = obj.competencia;
    return acc;
  }, {});

  const diccionarioDisponibilidad = datosDisponibilidad.reduce((acc, obj) => {
    acc[obj.id] = obj.competencia;
    return acc;
  }, {});

  const handleSeleccion = (event) => {
    const {name,value} = event.target;
    //console.log("Valor de datosForm.disponibilidad antes de la actualización:", datosForm.disponibilidad);
    //console.log('Nombre de la selección:', name);
    //console.log('Valor de la selección:', value);

    if(value.length<=5){
    if (name ==="industriasExperiencia"){
      setDatosForm({ ...datosForm, ["industriasExperiencia"]: value });
      setNuevosDatos({ ...nuevosDatos, ["industriasExperiencia"]: value});
      //console.log(diccionarioIndustrias)
      const nombreIndustrias = value.map(id=>diccionarioIndustrias[id]);
      setStringIndustrias(nombreIndustrias.join(', '))
      ;}  
    else if (name ==="competencias"){
      setDatosForm({ ...datosForm, ["competencias"]: value });

      setNuevosDatos({ ...nuevosDatos, ["competencias"]: value });
      //console.log(nuevosDatos)
      const nombreCompetencias = value.map(id=>diccionarioCompetencias[id]);
      setStringCompetencias(nombreCompetencias.join(', '))
    }
    else if (name ==="areas"){
      setDatosForm({...datosForm, ["areas"]: value});

      setNuevosDatos({ ...nuevosDatos, ["areasExperiencia"]: value });
      //console.log(nuevosDatos);
      const nombreAreas = value.map(id=>diccionarioAreas[id]);
      setStringAreas(nombreAreas.join(', '))
    }
    else if (name ==="disponibilidad"){
      const opcionesSeleccionadas = Array.isArray(value) ? value : [value];

      setDatosForm({...datosForm, ["disponibilidad"]: opcionesSeleccionadas});
      setNuevosDatos({ ...nuevosDatos, ["disponibilidad"]: opcionesSeleccionadas});
      //console.log(nuevosDatos);

      // Obtener los nombres de las disponibilidades seleccionadas
      const nombreSeleccion = opcionesSeleccionadas.map((opcion) => {
        const disponibilidad = datosDisponibilidad.find((d) => d.id === opcion);
        return disponibilidad ? disponibilidad.disponibilidad : '';
      });

      setStringDisponibilidad(nombreSeleccion.join(', '))
    }
    }else{
      window.alert("Número maximo de selecciones alcanzado")
    }};
  
  const handleContactos = (event) =>{
    const {name,value} = event.target;
    if (name === "contacto_nombre1"){
      let copiaContacto = [...contacto1];
      copiaContacto[0] = value;
      setContacto1(copiaContacto);
      //console.log(copiaContacto);
      //console.log(contacto1);

    }
    else if(name==="contacto_celular1"){
      let copiaContacto = [...contacto1];
      copiaContacto[2] = value;
      setContacto1(copiaContacto);
    }
    else if(name==="contacto_mail1"){
      let copiaContacto = [...contacto1];
      copiaContacto[1] = value;
      setContacto1(copiaContacto);
    }
    else if(name==="contacto_nombre2"){
      let copiaContacto = [...contacto2];
      copiaContacto[0] = value;
      setContacto2(copiaContacto);
    }
    else if(name==="contacto_celular2"){
      let copiaContacto = [...contacto2];
      copiaContacto[2] = value;
      setContacto2(copiaContacto);
    }
    else if(name==="contacto_mail2"){
      let copiaContacto = [...contacto2];
      copiaContacto[1] = value;
      setContacto2(copiaContacto);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Transformar campos vacíos en null antes de enviarlos a la API
    const datosActualizados = { ...nuevosDatos };

    for (const key in datosActualizados) {
      if (datosActualizados[key] === '') {
        datosActualizados[key] = null;
      }
    }
    const profesiones = [];
    if (profesion1 !== ''){
      profesiones.push(profesion1);
    }
    if (profesion2 !== ''){
      profesiones.push(profesion2);
    }
    if (profesiones.length !== 0){
      datosActualizados["profesiones"] = profesiones;
    }
    
    const contactos = [];
    if (mostrarContacto1){
      if (contacto1[0] !=='' || contacto1[1] !==''  || contacto1[2] !=='' ){
        contactos.push(contacto1);
      }}
    if (mostrarContacto2){
      if (contacto2[0] !=='' || contacto2[1] !==''  || contacto2[2] !=='' ){
        //console.log(contacto2);
        contactos.push(contacto2);
      }}
      if (contactos.length !==0){
        datosActualizados["contactos"] = contactos;
      }
    
    const token = Cookies.get('jwtToken');
    const responsePatch = await patch_user(token,datosActualizados);
    const response = await get_profile(token);
    const responseData = await response.json();
    if (!("rut" in datosActualizados) && datos.rut === null){
      datosActualizados["rut"] = null;
    }
    else if (!("rut" in datosActualizados) && datos.rut !== null){
      datosActualizados["rut"] = datos.rut;
    }
    //datosActualizados["rut"] = responseData.rut;
    setDatos(responseData);
    setNuevosDatos({});
    setModalIsOpen(false);

    setAnchorEl(null);
    setModalAnchorEl(null); 
    setMenuStatesAbrirModal({});

    //console.log(responsePatch);
    porcentaje(responseData);
  };


  const handleEliminate = async (event, id) => {
    event.preventDefault();
    // Transformar campos vacíos en null antes de enviarlos a la API
    const datosActualizados = { ...nuevosDatos };

    setMenuStatesAbrirSubMenu({});
    
    
    for (const key in datosActualizados) {
      if (datosActualizados[key] === '') {
        datosActualizados[key] = null;
      }
    }
    const profesiones = [];
    if (profesion1 !== ''){
      profesiones.push(profesion1);
    }
    if (profesion2 !== ''){
      profesiones.push(profesion2);
    }
    if (profesiones.length !== 0){
      datosActualizados["profesiones"] = profesiones;
    }

    const contactos = [];
    if (mostrarContacto1){
      if (contacto1[0] !=='' || contacto1[1] !==''  || contacto1[2] !=='' ){
        contactos.push(contacto1);
      }}
    if (mostrarContacto2){
      if (contacto2[0] !=='' || contacto2[1] !==''  || contacto2[2] !=='' ){
        //console.log(contacto2);
        contactos.push(contacto2);
      }}
      if (contactos.length !==0){
        datosActualizados["contactos"] = contactos;
      }

    /*
    if (datosActualizados.contactos.length != 0){
      if (id == 19 && datosActualizados.contactos.length == 1){
        datosActualizados.contactos = [];
      } else if (datosActualizados.contactos.length == 2){
        if (id == 19){
          datosActualizados.contactos.splice(0, 1);// elimina rimer elemento
          setMostrarContacto2(false);
        }
        else if (id == 20){
          datosActualizados.contactos.splice(1, 1);// elimina segundo elemento
          setMostrarContacto2(false);
        }
      }
    }*/
    //console.log('Enviando datos actualizados:', datosActualizados);

    // id cero
    if (id == 0){
      datosActualizados.universidad = null;
      datosActualizados.profesiones = [];
    } else if (id == 1){
      datosActualizados.postgrado = null;
    } else if (id == 2){
      datosActualizados.id_cargo = null;
      datosActualizados.id_industria_actual = null;
      datosActualizados.empresa_actual = null;
    } else if (id == 3){
      datosActualizados.id_cargo_adicional = null;
      datosActualizados.id_industria_adicional = null;
      datosActualizados.empresa_adicional = null;
    } else if (id == 4){
      datosActualizados.experienciaDirectorios = null;
    } else if (id == 5){
      datosActualizados.altaDireccion = null;
    } else if (id == 6){
      datosActualizados.id_anios_experiencia = null;
    }else if (id == 7){
      datosActualizados.areasExperiencia = []; // array
      datosActualizados.industriasExperiencia = []; // array
    }else if (id == 8){
      datosActualizados.competencias = []; // array
    } 
    else if (id == 9){
      datosActualizados.disponibilidad = []; // array
      datosActualizados.id_jornada = null;
    } else if (id == 10){
      datosActualizados.id_modalidad = null;
    } else if (id == 11){
      datosActualizados.id_posibilidad_cambiarse_region = null;
      datosActualizados.disposicion_viajar = null; // es un array
    }else if (id == 12){
      datosActualizados.idiomas = []; // es un array
    } 
    else if (id == 13){
      datosActualizados.factor = null;
    } else if (id == 14){
      datosActualizados.id_region_con_compromiso = null;
    } else if (id == 15){
      datosActualizados.nombrePuebloOriginario = null;
    }else if (id == 16){
      datosActualizados.id_personalidad = null;
    }else if (id == 17){
      datosActualizados.redesSociales = null;
    }else if (id == 18){
      datosActualizados.id_conocio_wot = null;
    }
    
    /*
    if (contactos.length !==0){
      if (id == 19 && contactos.length > 1){
        contactos.splice(0, 1);// elimina rimer elemento
      }else if (id == 19 && contactos.length == 1){
        contactos = []; // lista de arrays
      }else if (id == 20 && contactos.length >= 2){
        contactos.splice(1, 1);// elimina rimer elemento
      }

      datosActualizados["contactos"] = contactos;

    } else if (id == 20 || id == 19){
      contactos = []; // lista de arrays
      datosActualizados["contactos"] = contactos;
    }
    */

    const token = Cookies.get('jwtToken');
    const responsePatch = await patch_user(token,datosActualizados);
    const response = await get_profile(token);
    const responseData = await response.json();
    if (!("rut" in datosActualizados) && datos.rut === null){
      datosActualizados["rut"] = null;
    }
    else if (!("rut" in datosActualizados) && datos.rut !== null){
      datosActualizados["rut"] = datos.rut;
    }
    //datosActualizados["rut"] = responseData.rut;
    setDatos(responseData);
    setNuevosDatos({});
    setModalIsOpen(false);

    setAnchorEl(null);
    setModalAnchorEl(null); 
    setMenuStatesAbrirModal({});

    //console.log(responsePatch);
    porcentaje(responseData);
  };

const OpenModal = async () => {
  const datos_form = JSON.parse(JSON.stringify(datos));
 
  if (datos_form.paisDomicilio !== null ){
    datos_form['id_pais_domicilio'] = datos_form.paisDomicilio.id;
  }
  if (datos_form.paisDomicilio === null ){
    datos_form['id_pais_domicilio'] = '';
  } 
  if (datos_form.cargo !== null ){
    //console.log(datosForm.cargo);
    datos_form["id_cargo"] = datos_form.cargo.id;
  }
  if(datos_form.cargo===null ){
    datos_form["id_cargo"] = '';
  }
  if(datos_form.industria===null ){
    datos_form["id_industria_actual"] = '';
  }
  if (datos_form.industria !== null ){
    datos_form["id_industria_actual"] = datos_form.industria.id;
  }
  if (datos_form.aditionalCargo !== null ){// agregado
    //console.log(datosForm.cargo);
    datos_form["id_cargo_adicional"] = datos_form.aditionalCargo.id;
    setMostrarCargoAdicional(true);
  }
  if(datos_form.aditionalCargo===null ){
    datos_form["id_cargo_adicional"] = '';
  }
  if(datos_form.aditionalIndustria===null ){
    datos_form["id_industria_adicional"] = '';
  }
  if (datos_form.aditionalIndustria !== null){ // agregado
    datos_form["id_industria_adicional"] = datos_form.aditionalIndustria.id;
    setMostrarCargoAdicional(true)
  }
  if (datos_form["profesion"].length !==0){
    if (datos_form["profesion"].length === 1){
      datos_form["profesion1"] = datos_form.profesion[0].id
      datos_form["profesion2"] = ''
    }
    if (datos_form["profesion"].length === 2){
        datos_form["profesion1"] = datos_form.profesion[0].id
        datos_form["profesion2"] = datos_form.profesion[1].id
    }
  }
  if (datos_form["profesion"].length ===0){
    datos_form["profesion1"] = ''
    datos_form["profesion2"] = ''
  }
  if (datos_form["aniosExperiencia"] === null){
    datos_form["id_anios_experiencia"] = '';
  }
  if (datos_form["aniosExperiencia"] !== null){
    datos_form["id_anios_experiencia"] = datos_form.aniosExperiencia.id;
  }
  if (datos_form["disponibilidad"].length !== 0){
    datos_form["disponibilidad"] = datos_form.disponibilidad.map(disponibilidad=>disponibilidad.id);
    const nombreDisponibilidad = datos_form["disponibilidad"].map(id=>diccionarioDisponibilidad[id]);
    setStringDisponibilidad(nombreDisponibilidad.join(', '))
  }
  if (datos_form["disponibilidad"].length === 0){
    datos_form["disponibilidad"] = [];
  }
  if (datos_form["idiomas"].length !== 0){
    datos_form["idiomas"] = datos_form.idiomas[0].id;
  }
  if (datos_form["idiomas"].length === 0){
    datos_form["idiomas"] = '';
  }
  if (datos_form["tipoJornada"] !== null){
    datos_form["id_jornada"] = datos_form.tipoJornada.id;
  }
  if (datos_form["tipoJornada"] === null){
    datos_form["id_jornada"] = '';
  }
  if (datos_form["areas"].length !== 0){
    datos_form["areas"] = datos_form.areas.map(area=>area.id);
    //console.log(datos_form["areas"])
    datos_form["areas"].map(id=> console.log(id))
    //console.log(diccionarioAreas)
    const nombreAreas = datos_form["areas"].map(id=>diccionarioAreas[id]);
    //console.log(nombreAreas)
    setStringAreas(nombreAreas.join(', '))
  }
  if (datos_form["industrias"].length !== 0){
    
    datos_form["industriasExperiencia"] = datos_form.industrias.map(industria=>industria.id);
    const nombreIndustrias = datos_form["industriasExperiencia"].map(id=>diccionarioIndustrias[id]);
    setStringIndustrias(nombreIndustrias.join(', '))
  }
  if (datos_form["industrias"].length === 0){
    datos_form["industriasExperiencia"] = [];
  }
  if (datos_form["competencia"].length !== 0){
    datos_form["competencias"] = datos_form.competencia.map(competencia=>competencia.id);
    const nombreCompetencias = datos_form["competencias"].map(id=>diccionarioCompetencias[id]);
    setStringCompetencias(nombreCompetencias.join(', '))
  }
  if (datos_form["competencia"].length === 0){
    datos_form["competencias"] = [];
  }
  if (datos_form["conocioWOT"] !== null){
    datos_form["id_conocio_wot"] = datos_form.conocioWOT.id;
  }
  if (datos_form["conocioWOT"] === null){
    datos_form["id_conocio_wot"] = '';
  }
  if (datos_form["personalidad"] !== null){
    datos_form["id_personalidad"] = datos_form.personalidad.id;
  }
  if (datos_form["personalidad"] === null){
    datos_form["id_personalidad"] = '';
  }
  if (datos_form["posibilidadCambiarseRegion"] !== null){
    datos_form["id_posibilidad_cambiarse_region"] = datos_form.posibilidadCambiarseRegion.id;
  }
  if (datos_form["posibilidadCambiarseRegion"] === null){
    datos_form["id_posibilidad_cambiarse_region"] = '';
  }
  if (datos_form["tipoModalidad"] !== null){
    datos_form["id_modalidad"] = datos_form.tipoModalidad.id;
  }
  if (datos_form["tipoModalidad"] === null){
    datos_form["id_modalidad"] = '';
  }
  if (datos_form["disposicion_viajar"] === null){
    datos_form["disposicion_viajar"] = '';
  }
  if (datos_form["factor"] !== null && datos_form["factor"]!=="No"){
    setMostrarFactor(true);
    setSelectedFactor('Si');
  }
  if (datos_form["factor"]==="No"){
    setMostrarFactor(false);
    setSelectedFactor('No');
  }
  if (datos_form["factor"]===null){
    setMostrarFactor(false);
    setSelectedFactor('');
  }
  if (datos_form["postgrado"] !== null && datos_form["postgrado"] !== "No"){
    setMostrarCuadroTextoPostgrado(true);
    setSelectedPostgrado('Si');
  }
  if (datos_form["regionCompromiso"] !== null && datos_form["regionCompromiso"]!=="No" && selectedCompromiso !="No"){ // agregado
    setMostrarCompromiso(true);
    setSelectedCompromiso("Si");
    datos_form["id_region_con_compromiso"] = datos_form.regionCompromiso.id;
  }
  if (datos_form["regionCompromiso"] === null ){
    datos_form["id_region_con_compromiso"] = '';
  }
  if (datos_form["nombrePuebloOriginario"] !== null ){
    setMostrarPueblo(true);
    setSelectedPueblo('Si');
  }
  if (datos.paisDomicilio !== null){
    let codigoTelefonico = '';
          
    if (datos.paisDomicilio.id === 1) {
      codigoTelefonico = '+56';
    } else {
      const codigoAsociado = codigosTelefonicos.find((codigo) => codigo.nombre === datos.paisDomicilio.pais);
      codigoTelefonico = codigoAsociado ? codigoAsociado.codigo : '';
    }
    setCodigoTelefonico(codigoTelefonico);
  }
  setDatosForm(datos_form);
  setModalIsOpen(true);
}


  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
      
      setIsLoggedIn(true);
      obtener_recomendacion_comunidades();
    } else {
      setIsLoggedIn(false);
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    setLoadingPerfil(true);
    const fetchDataPerfil = async () => {
      try {
        await mostrarUser(); 
        setLoadingPerfil(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchDataPerfil();
    
  }, []);

  const mostrarUser = async () => {
    try{
      const jwtToken = Cookies.get('jwtToken');
      const response = await get_profile(jwtToken);
      if (response.status === 401) {
        Cookies.remove('jwtToken');
        navigate("/login");
      };
      const responseData = await response.json();
      setDatos(responseData);
      setLoadingPerfil(false);
      porcentaje(responseData);
      const token = Cookies.get('jwtToken');
      const responseCv = await get_cv(token);
      
      if(responseCv.status === 200){
        setCv(responseCv.data.cvlink)
      }
      
      const responsePaises = await get_paises();
      setDatosPaises(responsePaises.data);
      const responseIndustrias = await get_industrias();
      setDatosIndustrias(responseIndustrias.data);
      const responseProfesiones = await get_profesiones();
      setDatosProfesiones(responseProfesiones.data);
      const responseUniversidades = await get_universidades();
      setDatosUniversidades(responseUniversidades.data);
      const responseCargos = await get_cargos();
      setDatosCargos(responseCargos.data);
      const responseAnios = await get_anos_experiencia();
      setDatosAnios(responseAnios.data);
      const responseDisponibilidad = await get_disponibilidades();
      setDatosDisponibilidad(responseDisponibilidad.data);
      const responseJornada = await get_jornadas();
      setDatosJornada(responseJornada.data);
      const responseModalidades = await get_modalidades();
      setDatosModalidades(responseModalidades.data);
      const responseIdiomas = await get_idiomas();
      setDatosIdiomas(responseIdiomas.data);
      const responseCompromiso = await get_regiones();
      
      setDatosCompromiso(responseCompromiso.data);
      const responseWot = await get_conocio_wot();
      setDatosWot(responseWot.data);
      const responsePersonalidad = await get_formularios();
      setDatosPersonalidad(responsePersonalidad.data);
      const responseAreas = await get_areas();
      setDatosAreas(responseAreas.data);
      const responseCompetencias = await get_competencias();
      setDatosCompetencias(responseCompetencias.data);
      
      //console.log(responseData);
      const responseNuevo = await get_profile(jwtToken);
      const responseDataNuevo = await responseNuevo.json();
      setDatos(responseDataNuevo);
      
      const patch = {
        "declaracion": true
      };
      await patch_user(jwtToken,patch);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const menuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    style: {
      
    }
  };
  
  const deleteUser = async () => { // onClick={this.deleteUser} en button
    try {
      const jwtToken = Cookies.get('jwtToken');
      const response = await delete_user(jwtToken);
      if (response.status == 200) {
        Cookies.remove('jwtToken');
        window.alert('Cuenta eliminada');
        navigate('/login');
      }
    } catch (error) {
      alert('Fallo a eliminar cuenta.');
      console.error(error);
    }
  };

  const changePassword = async (event) => {
    //setModalPublicationIsOpen(false);
    
    try {
      const formData = new FormData(event.target); // Accede a los datos del formulario
    
      const oldPassword = formData.get("oldPassword");
      const newPassword = formData.get("newPassword");
      const newPasswordConfirmation = formData.get("newPasswordConfirmation");

      const token = Cookies.get('jwtToken');
      let response = await change_password(oldPassword, newPassword, newPasswordConfirmation, token);
      const responseData = await response.json();
      //console.log('Response Data:', responseData);
      //console.log(response.status);
      
      setModalContraIsOpen(false);

    } catch (error) {
      alert('Signup failed. Please try again.');
      console.error(error);
    }
    
  };
  const bordeElipse1 = currentStep === 1 ? 'borde-elipse' : '';
  const bordeElipse2 = (currentStep >=2 && currentStep <=4) ? 'borde-elipse' : '';
  const bordeElipse3 = currentStep === 5 ? 'borde-elipse' : '';
  const bordeElipse4 = (currentStep >=6 && currentStep <=7) ? 'borde-elipse' : '';
  const bordeElipse5 = currentStep >7 ? 'borde-elipse' : '';
  const destacado1 = currentStep === 1 ? 'destacado' : '';
  const destacado2= (currentStep >=2 && currentStep <=4) ? 'destacado' : '';
  const destacado3 = currentStep === 5 ? 'destacado' : '';
  const destacado4 = (currentStep >=6 && currentStep <=7) ? 'destacado' : '';
  const destacado5 = currentStep >7 ? 'destacado' : '';

  const NavegarEditarPerfil = () => {
    OpenModal();
    setModalIsOpen(false);
    navigate("/editar_perfil");
  };

  const [modalBotonOpen, setModalBotonOpen] = useState(false);

  const openBotonModal = () => {
    setModalBotonOpen(true);
  };


  // BOTTON SEGUIR -> SIGUIENDO

  const [button1Active, setButton1Active] = useState(false);
  const [button2Active, setButton2Active] = useState(false);
  const [button3Active, setButton3Active] = useState(false);
  const [button4Active, setButton4Active] = useState(false);

  const handleButtonClick = (button) => {
    switch (button) {
      case 'button1':
        setButton1Active(!button1Active);
        break;
      case 'button2':
        setButton2Active(!button2Active);
        break;
      case 'button3':
        setButton3Active(!button3Active);
        break;
      case 'button4':
        setButton4Active(!button4Active);
        break;
      default:
        break;
    }
  };

  const getButtonClass = (buttonState) => {
    return buttonState ? 'button-siguiendo-active' : 'button-siguiendo-inactivate';
  };

  const [communities, setCommunities] = useState([]);

  const fetchCommunities = async () => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_communities(token);
      const responseData = await response.data;
      setCommunities(responseData.slice(0, 5));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleBlur = (event) => {
    const { id, name, value } = event.target;
    const formattedValue = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    }).format(parseInt(value, 10));
    setDatos((prevDatos) => ({ ...prevDatos, [name]: formattedValue }));
  };

  const handleFocus = (event) => {
    const { id, name, value } = event.target;
    const rawValue = value.replace(/[^\d]/g, ''); // Remove any non-numeric characters
    setDatos((prevDatos) => ({ ...prevDatos, [name]: rawValue }));
  };

  const handleRentChange = (event) => {
    const rawValue = event.target.value.replace(/[^\d]/g, '');
    const formattedValue = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
    }).format(rawValue);
    setFormData({
      ...formData,
      expectatviaRenta: formattedValue
    });
  };
  useEffect(() => {
    fetchCommunities();
  }, []);

  const [joinComunitySuccess, setJoinComunitySuccess] = useState(false);
  const [joinComunityError, setJoinComunityError] = useState(false);

  const handleJoinCommunity = async (community_id) => {
    try {
      const token = Cookies.get('jwtToken');
      let response = await join_community(token, community_id);
      if (response.status === 200) {
        try {
          // Make a GET request to your API endpoint
          setJoinComunitySuccess(true);
          setTimeout(() => {
            setJoinComunitySuccess(false);
          }, 2000);
          const response = await get_communities(token);
          const responseData = await response.data;
          setCommunities(responseData);
        } catch (error) {
          setJoinComunityError(true);
          setTimeout(() => {
            setJoinComunityError(false);
          }, 2000);
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      alert('Error al seguir la comunidad');
      console.error(error);
    }
  };

return (
  <><div className='div_profile'>
    {isLoggedIn && (

      <div className="desktop_perfil">
      <div className="div">
        <div className="overlap-group">
        <div className="rectangulo_principal">
          {joinComunitySuccess &&(
            <JoinComunityAlert/>
          )}
          {joinComunityError &&(
            <ErrorJoinComunityAlert/>
          )}

          <div className="flex-container">
            <div className="group" ></div>
            <div className="rectangle-fucsia" ></div>
          </div>
          <div className="rectangulo_seccion">
            {loadingPerfil && <p className="nombre-apellido">Loading...</p>}
            {!loadingPerfil && <p className="nombre-apellido">{datos.nombre} {datos.apellido}</p>}
            <div className="brief-container-scrollable">
              {datos.brief != null ? (
                <p className="sobre-ti">{datos.brief}</p>
              ) : (
                <p className="dato-faltacompletar2">Falta agregar una breve descripción sobre ti</p>
              )}
            </div>

            {/* <p className="element-contactos">50 contactos</p> */}
            <div className="flex-container">
              {Math.round(porcentajeProgress) <= 100 &&(
              <button onClick={() => OpenModal()} className="button_rosa" /*disabled={porcentajeProgress >= 100 }*/>Completar formulario</button>
              )}
              <button className={Math.round(porcentajeProgress) < 100 ? "button_buscando_empleo": "button_buscando_empleo margin-button"} onClick={NavegarEditarPerfil}>Editar Perfil</button>
              {/* <button className="button_ajustes" onClick={() => navigate("/ajustes")}>
              <img className="ajustes" alt="Ajustes" src={ajustes1}/>Ajustes</button> */}
            </div>
            <p></p>
            <div className="linea_gris"></div>
          </div>
          
          <div className="rectangle_gris"></div>

          {/* <div className="circulo-blanco" ></div>
          <img className="imagen" alt="Imagen" src={imagen1} /> */}

          <div className="linea_rosa"></div>
          <div className="linea_rosa_corta"></div>
          <img className="mujer" alt="Mujer" src={imagen_perfil} />
          <div className="elipse-fucsia"></div>
          {Math.round(porcentajeProgress) <= 100 && (
          <div className="rectangulo_seccion">
            <p className="nombre-formulario">Formulario</p>
            <p className="sobre-ti">En este formulario te preguntamos lo necesario para crear tu perfil de mujer en WoT</p>
            <p className="element-contactos">Tiempo de llenado: 7 minutos aprox</p>
            <LinearProgress sx={{
                                  height: 10, // Ajusta la altura de la barra de progreso
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: 'primary.main', // Color de la barra de progreso
                                  },
                                  '& .MuiLinearProgress-barColorPrimary': {
                                    backgroundColor: '#ee4296', // Color de la barra cuando tiene el color primario
                                  },
                                  '& .MuiLinearProgress-colorPrimary': {
                                    backgroundColor: 'white', // Color de fondo de la barra
                                  },
                                  borderRadius: 5, // Redondea las esquinas
                                  width: '45vw',
                                  marginLeft:'7vw',
                                  
                                }}
                variant="determinate"
                value={porcentajeProgress}/>            
            
            <p style={{marginLeft:'28vw'}}>{Math.round(porcentajeProgress)}%</p>
            <div className="linea_gris"></div>

          </div>
          )}

          <div className="rectangulo_seccion">
            <p className="perfil-letra">Perfil</p>
          
            <div className="horizontal-bar">
              
              <button onClick={() => handleInfoChange(1)} className={activeInfo === 1 ? "button-activate-infoprofesional" : "button-inactivate-infoprofesional"}>
                Información profesional
              </button>

              <button onClick={() => handleInfoChange(2)} className={activeInfo === 2 ? "button-activate" : "button-inactivate"}>
                Disponibilidad
              </button>

              <button onClick={() => handleInfoChange(3)} className={activeInfo === 3 ? "button-activate-infoadicional" : "button-inactivate-infoadicional"}>
                Información adicional
              </button>

              <button onClick={() => handleInfoChange(4)} className={activeInfo === 4 ? "button-activate" : "button-inactivate"}>
                Referencias
              </button>
            </div>
            <div className="select-bar">
              <select value={activeInfo} onChange={(e) => handleInfoChange(parseInt(e.target.value))}>
                <option value={1}>Información profesional</option>
                <option value={2} >Disponibilidad</option>
                <option value={3}>Información adicional</option>
                <option value={4} >Referencias</option>
              </select>
            </div>


          </div> 

          {activeInfo === 1 &&
            <div className="info-container">
            {/*FORM VACIO*/}
            {((datos.profesion != null && datos.profesion.length == 0) && datos.universidad == null) && datos.postgrado == null &&
            datos.empresa_actual == null && datos.cargo == null && datos.industria == null && datos.aniosExperiencia == null &&
            datos.empresa_adicional == null && datos.aditionalCargo == null && datos.aditionalIndustria == null &&
            datos.experienciaDirectorios == null && datos.altaDireccion == null && (datos.areas != null && datos.areas.length == 0) &&
            (datos.industrias != null && datos.industrias.length == 0) && (datos.competencia != null && datos.competencia.length == 0) &&
                (<div className='contenedor-mayor'>
                  <div className="flex-container"> 
                    <div className='container-comunidad'> 
                      <img className="imagen_porcompletar" alt="Mujer" src={imagen_porcompletar} />
                      <p className="letra-gris-faltacompletar">Un nuevo comienzo</p>
                      <p className="letra-rosada-faltacompletar">Algo para agregar sobre ti</p>
                    </div>
                </div>
              </div>)}

              {((datos.profesion != null && datos.profesion.length != 0) || datos.universidad != null) &&
                <div className='contenedor-mayor'>
                  <div className="flex-container"> 
                    <div className='container-comunidad'> 
                      {datos.profesion.length == 2 && <p className="letra-gris">Profesiones</p>}
                      {datos.profesion.length == 2 && datos.universidad == null && <p className="letra-gris-clara">{datos.profesion[0].nombre} y {datos.profesion[1].nombre}</p>}
                      {datos.profesion.length == 2 && datos.universidad != null && <p className="letra-gris-clara">{datos.profesion[0].nombre} y {datos.profesion[1].nombre}, {datos.universidad}</p>}

                      {datos.profesion.length == 1 && <p className="letra-gris">Profesión</p>}
                      {datos.profesion.length == 1 && datos.universidad == null && <p className="letra-gris-clara">{datos.profesion[0].nombre}</p>}
                      {datos.profesion.length == 1 && datos.universidad != null && <p className="letra-gris-clara">{datos.profesion[0].nombre}, {datos.universidad}</p>}

                      {datos.universidad != null && datos.profesion.length == 0 && <p className="letra-gris">Universidad</p>}
                      {datos.universidad != null && datos.profesion.length == 0 && <p className="letra-gris-clara">{datos.universidad}</p>}
                    </div>

                      <button id={`menu-perfil-Profesiones`} aria-controls="menu-perfil-Profesiones" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 0)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-Profesiones`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[0] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                        <MenuItem onClick={(event) => handleEliminate(event, 0)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 0)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[0] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                      <div>
                      <div className='form-group'>
                        <label className='label-negrita'>Profesión</label>
                        <label className='label-gris2'>Elige la opción que más se acomode a tu profesión</label>
                        <select
                          className='form-control'
                          name='profesion1'
                          value={datosForm.profesion1}
                          onChange={(event) => handleArray(event)}>
                          <option value="">Escoge una opcion</option>
                          {datosProfesiones.map(profesion => (
                            <option key={profesion.id} value={profesion.id}>
                              {profesion.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='form-group'>
                          <label className='label-negrita'>Profesión 2</label>
                          <label className='label-gris2'>Selecciona otra profesión si es que tienes</label>
                          <select
                            className='form-control'
                            name='profesion2'
                            value={datosForm.profesion2 || ''}
                            onChange={(event) => handleArray(event)}>
                            <option value="">Escoge una opcion</option>
                            {datosProfesiones.map(profesion => (
                              <option key={profesion.id} value={profesion.id}>
                                {profesion.nombre}
                              </option>
                            ))}
                          </select>
                        </div>
                      <div className='form-group'>
                          <label className='label-negrita'>Universidad</label>
                          <label className='label-gris2'>Selecciona tu universidad de pregrado. Si es que no está en la lista selecciona "Otra" e indica la carrera.</label>
                          <select
                            value={datosForm.universidad || ''}
                            className='form-control'
                            name='universidad'
                            onChange={handleDropdownChange}>
                            <option value="">Selecciona una universidad:</option>
                            {datosUniversidades.map(universidad => (
                              <option key={universidad.id} value={universidad.nombre}>
                                {universidad.nombre}
                              </option>
                            ))}
                          </select>
                        </div>
                        </div>
                        <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                        </form>
                      
                      </Modal>
                    


                  </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              {datos.postgrado != null && datos.postgrado != "No" &&
                <div className='contenedor-mayor'>
                  <div className="flex-container"> 
                    <div className='container-comunidad'> 
                      {datos.postgrado != null && datos.postgrado != "No" && <p className="letra-gris">Postgrado</p>}
                      {datos.postgrado != null && datos.postgrado != "No" && <p className="letra-gris-clara">{datos.postgrado}</p>}
                    </div>


                    <button id={`menu-perfil-Postgrado`} aria-controls="menu-perfil-Postgrado" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 1)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-Postgrado`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[1] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                        <MenuItem onClick={(event) => handleEliminate(event, 1)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 1)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[1] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                      <div className='form-group'>
                        <label htmlFor="postgradoElection" className="label-negrita">Postgrado</label>
                        <label className='label-gris4'>Se considera como tal al que acredita la obtención de un grado académico equivalente a magíster o doctorado, otorgado por una universidad reconocida por el Estado, nacional o extranjera.</label>
                        <select
                          id="postgradoElection"
                          name="postgrado"
                          onChange={handleAdicional}
                          value={selectedPostgrado}
                          className="form-control">
                          <option value="No">No</option>
                          <option value="Si">Si</option>
                        </select>
                      </div>
                      {mostrarCuadroTextoPostgrado && (
                        <div className='form-group'>
                          <label htmlFor="postgrado" className="label-negrita">Qué postgrado tienes</label>
                          <label className='label-gris'>Indicar título y universidad</label>
                          <input
                            type="text"
                            id="postgrado"
                            name="postgrado"
                            value={datosForm.postgrado}
                            onChange={handleInputChange}
                            className="form-control"
                            />
                        </div>
                      )}
                      <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                      </form>
                      </Modal>
                  
                  
                  </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              
              {(datos.empresa_actual != null || datos.cargo != null || datos.industria != null) &&
                <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                  {(datos.cargo != null || datos.empresa_actual != null || datos.industria != null) && <p className="letra-gris">Cargo actual</p>}
                  {datos.cargo != null && datos.empresa_actual != null && <p className="letra-gris-clara">{datos.cargo.cargo}, {datos.empresa_actual}</p>}
                  {datos.empresa_actual != null && datos.cargo == null && <p className="letra-gris-clara">Empresa: {datos.empresa_actual}</p>}
                  {datos.cargo != null && datos.empresa_actual == null && <p className="letra-gris-clara">{datos.cargo.cargo}</p>}
                  {datos.industria != null && <p className="letra-gris-clara"> Industria: {datos.industria.nombre_industria}</p>}
                  </div>
                  <button id={`menu-perfil-CargoActual`} aria-controls="menu-perfil-CargoActual" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 2)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-CargoActual`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[2] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                        <MenuItem onClick={(event) => handleEliminate(event, 2)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 2)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[2] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                    
                    <form className='formulario-center'>
                      {datosCargos ? (
                        <div className='form-group'>
                          <label className='label-negrita'>Cargo actual</label>
                          <label className='label-gris2'>Elige la opción que más se acomode a tu cargo. Si no tienes cargo selecciona "Ninguno"</label>
                          <select
                            value={datosForm.id_cargo || ''}
                            className='form-control'
                            name="id_cargo"
                            onChange={(event) => handleDropdownChange(event)}>
                            <option value="">Selecciona un cargo:</option>
                            {datosCargos.map(cargo => (
                              <option key={cargo.id} value={cargo.id}>
                                {cargo.cargo}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}
                    <div>
                        {datosIndustrias ? (
                          <div className='form-group'>
                            <label className='label-negrita'>Industria Actual</label>
                            <label className='label-gris2'>Se entiende como industria el área de la economía en la que tu empresa u organización se desempeña</label>
                            <select
                              value={datosForm.id_industria_actual || ''}
                              className='form-control'
                              name='id_industria_actual'
                              onChange={(event) => handleDropdownChange(event)}>
                              <option value="">Escoga una opción</option>
                              {datosIndustrias.map(industria => (
                                <option key={industria.id} value={industria.id}>
                                  {industria.nombre_industria}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : null}
                      </div>
                        <div className='form-group'>
                          <label htmlFor="empresa" className="label-negrita">Empresa</label>
                          <label className='label-gris2'>Nombre de la empresa en la que trabajas</label>
                          <input
                            type="text"
                            id="empresa"
                            name="empresa_actual"
                            value={datos.empresa_actual || ''}
                            onChange={handleInputChange}
                            className="form-control" />
                        </div>
                        <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                      </form>
                      </Modal>


                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              
              {(datos.empresa_adicional != null || datos.aditionalCargo != null || datos.aditionalIndustria != null) &&
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                  {datos.aditionalCargo != null && <p className="letra-gris">Cargo adicional</p>}
                  {datos.aditionalCargo != null && mostrarCargoAdicional != false && datos.empresa_adicional != null  && <p className="letra-gris-clara">{datos.aditionalCargo.cargo}, {datos.empresa_adicional}</p>}
                  {datos.aditionalCargo != null && mostrarCargoAdicional != false && datos.empresa_adicional == null && <p className="letra-gris-clara">{datos.aditionalCargo.cargo}</p>}
                  {datos.empresa_adicional != null  && mostrarCargoAdicional != false && datos.aditionalCargo == null && <p className="letra-gris-clara">Empresa: {datos.empresa_adicional}</p>}
                  {datos.aditionalIndustria != null && mostrarCargoAdicional != false && <p className="letra-gris-clara">Industria: {datos.aditionalIndustria.nombre_industria}</p>}
                  {mostrarCargoAdicional == false && <p className="letra-gris-clara">No</p>}
                  </div>

                  <button id={`menu-perfil-CargoAdicional`} aria-controls="menu-perfil-CargoAdicional" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 3)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-CargoAdicional`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[3] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                      <MenuItem onClick={(event) => handleEliminate(event, 3)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 3)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[3] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
   
                      <form className='formulario-center'>
                      <div className='form-group'>
                        <label htmlFor="rolAdicional" className="label-negritadesem">¿Desempeñas algún otro rol, cargo o funcion actualmente?</label>
                        <p className=""></p>
                        <select
                          id="rolAdicional"
                          name="rolAdicional"
                          className="form-control"
                          onChange={(event) => handleAdicional(event)}>
                          <option value="">Escoga una opción</option>
                          <option value="">No</option>
                          <option value="1">Si</option>
                        </select>
                      </div>

                      {mostrarCargoAdicional && (
                      <><label></label>
                      <div className='form-group'>
                        <label className='label-negrita'>Cargo adicional o complementario</label>
                        <select
                          className={`form-control ${isFieldEmpty(datosForm.id_cargo_adicional) ? 'form-control-no-respondido' : 'form-control'}`}
                          name="id_cargo_adicional"
                          value={datosForm.id_cargo_adicional}
                          onChange={(event) => handleDropdownChange(event)}>
                          <option value="">Escoga una opción</option>
                          {datosCargos.map(cargo => (
                            <option key={cargo.id} value={cargo.id}>
                              {cargo.cargo}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='form-group'>
                            <label className='label-negrita'>Industria adicional o complementaria</label>
                            <select
                              className={`form-control-industriaAdicional ${isFieldEmpty(datosForm.id_industria_adicional) ? 'form-control-industriaAdicional-no-respondido' : 'form-control-industriaAdicional'}`}
                              name='id_industria_adicional'
                              value={datosForm.id_industria_adicional}
                              onChange={(event) => handleDropdownChange(event)}>
                              <option value="">Escoga una opción</option>
                              {datosIndustrias.map(industria => (
                                <option key={industria.id} value={industria.id}>
                                  {industria.nombre_industria}
                                </option>
                              ))}
                            </select>
                          </div></>
                      )}

                      {mostrarCargoAdicional && (
                        <><div className='form-group'>
                                <label htmlFor="empresa" className="label-negrita">Empresa adicional:</label>
                                <input
                                  type="text"
                                  id="empresa"
                                  name="empresa_adicional"
                                  value={datos.empresa_adicional || ''}
                                  onChange={handleInputChange}
                                  className="form-control" />
                              </div></>        
                      )}
                      <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                      </form>

                      </Modal>



                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              {(datos.experienciaDirectorios != null && datos.experienciaDirectorios == true) &&
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                  {datos.experienciaDirectorios != null && datos.experienciaDirectorios == true && <p className="letra-gris">¿Tiene experiencia en directorios?</p>}
                  {datos.experienciaDirectorios != null && datos.experienciaDirectorios == true && <p className="letra-gris-clara">Si</p>}
                  </div>
                
                  <button id={`menu-perfil-ExperienciaDirectorios`} aria-controls="menu-perfil-ExperienciaDirectorios" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 4)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-ExperienciaDirectorios`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[4] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                      <MenuItem onClick={(event) => handleEliminate(event, 4)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 4)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[4] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                      <div className='form-group'>
                        <label htmlFor="experienciaDireccion" className="label-negrita">Experiencia en directorios</label>
                        <label className='label-gris'>Esto significa ejercer o haber ejercido el rol de directora</label>
                        <select
                          id="experienciaDireccion"
                          name="experienciaDirectorios"
                          value={datosForm.experienciaDirectorios || ''}
                          onChange={(event) => handleDropdownChange(event)}
                          className="form-control">
                          <option value=''>Escoge una opción</option>
                          <option value={true}>Si</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                      <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                      </form>
                      </Modal>
                
                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              {(datos.altaDireccion != null  && datos.altaDireccion == true) &&
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                  {datos.altaDireccion != null && datos.altaDireccion == true && <p className="letra-gris">¿Tiene experiencia en alta dirección?</p>}
                  {datos.altaDireccion != null && datos.altaDireccion == true && <p className="letra-gris-clara">Si</p>}
                  </div>

                  <button id={`menu-perfil-AltaDireccion`} aria-controls="menu-perfil-AltaDireccion" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 5)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-AltaDireccion`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[5] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                      <MenuItem onClick={(event) => handleEliminate(event, 5)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 5)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[5] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                        <div className='form-group'>
                          <label htmlFor="formacionDireccion" className="label-negrita">Formacion en alta dirección</label>
                          <select
                            id="formacionDireccion"
                            name="altaDireccion"
                            value={datosForm.altaDireccion || ''}
                            onChange={(event) => handleDropdownChange(event)}
                            className="form-control">
                            <option value=''>Escoge una opción</option>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                        <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                        </form>
                      </Modal>
                

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              {(datos.aniosExperiencia != null) &&
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                  {datos.aniosExperiencia != null && (
                    <div>
                      <p className="letra-gris">Años de experiencia</p>
                      <p className="letra-gris-clara">
                        {datos.aniosExperiencia.rango_experiencia_hasta === 500
                          ? "+25"
                          : `${datos.aniosExperiencia.rango_experiencia_desde} - ${datos.aniosExperiencia.rango_experiencia_hasta}`}
                      </p>
                    </div>
                  )}
                  </div>
                  
                  <button id={`menu-perfil-AnosExperiencia`} aria-controls="menu-perfil-AnosExperiencia" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 6)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-AnosExperiencia`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[6] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                      <MenuItem onClick={(event) => handleEliminate(event, 6)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 6)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[6] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                      <div className='form-group'>
                        <label className='label-negrita'>Años de experiencia</label>
                        <select
                          className='form-control'
                          name='id_anios_experiencia'
                          value={datosForm.id_anios_experiencia}
                          onChange={(event) => handleDropdownChange(event)}>
                          <option value="">Escoge una opción</option>
                            {datosAnios.map(rangos => (
                              <option key={rangos.id} value={rangos.id}>
                                {rangos.rango_experiencia_hasta === 500
                                  ? "+25"
                                  : `${rangos.rango_experiencia_desde} - ${rangos.rango_experiencia_hasta}`}
                              </option>
                          ))}
                        </select>
                      </div>
                      <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                      </form>
                      </Modal>
                
                </div>
                <div className='linea-gris-mediana'></div>
              </div>}


              {((datos.areas != null && datos.areas.length !== 0) || (datos.industrias != null && datos.industrias.length !== 0)) &&
              <div className='contenedor-mayor'>
                <div className="flex-container">                
                  <div className='contenedor-acotado'>
                    {datos.areas != null && datos.areas.length != 0 && <p className="letra-gris">Áreas con experiencia:</p> }
                    {datos.areas != null && datos.areas.length != 0 && datos.areas?.map((area, index)=>(
                      <p key={"areas_experiencia" + index.toString()} className="letra-gris-clara">{area.nombre}</p>))}
                  </div>
                  <div className='barra-vertical-grande'></div>
                  <div className='contenedor-acotado'>
                    {datos.industrias != null && datos.industrias.length != 0 && <p className="letra-gris">Industrias con experiencia:</p> }
                    {datos.industrias != null && datos.industrias.length != 0 && datos.industrias?.map((industria, index)=>(
                      <p key={"industrias_experiencia" + index.toString()} className="letra-gris-clara">{industria.nombre_industria}</p>))}
                  </div>      

 
                  <button id={`menu-perfil-IndustriasAreasExperiencia`} aria-controls="menu-perfil-IndustriasAreasExperiencia" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 7)}>
                        <p className='puntos'>...</p>
                      </button>  
                  <Menu id={`menu-perfil-IndustriasAreasExperiencia`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[7] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                  <MenuItem onClick={(event) => handleEliminate(event, 7)}>Eliminar</MenuItem>
                      <MenuItem onClick={(event)=>handleClickAbrirModal(event, 7)}>Editar</MenuItem>
                    </Menu>
                    <Modal isOpen={menuStatesAbrirModal[7] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                    <form className='formulario-center'>
                    <div className='form-group'>
                      <label className='label-negrita'>Áreas de experiencia</label>
                      <label className='label-roja'>Seleccionar máximo 5 respuestas</label>
                      <label className='label-gris2'>Indica las áreas en las que has trabajado anteriomente</label>
                    <div className='form-group'>
                      <Select 
                        className='form-control max-width'
                        multiple
                        value={datosForm.areas}
                        onChange={handleSeleccion}
                        MenuProps={menuProps}
                        inputProps={{
                          name: 'areas',
                          id: 'opciones-multiple',
                        }}
                        >
                        {datosAreas.map(opcion =>(
                          <MenuItem key={opcion.id} value={opcion.id}>{opcion.nombre}</MenuItem>
                        ))}
                        </Select>
                      <label className='label-negrita-experiencia'>Areas seleccionadas: {stringAreas}</label>
                    </div> </div>
                    <div className='form-group'>
                      <label className='label-negrita'>Sector o industria en la que tienes experiencia</label>
                      <label className='label-roja'>Seleccionar máximo 5 respuestas</label>
                      <Select 
                          className={`form-control ${isFieldEmpty(datosForm.industriasExperiencia) ? 'form-control-no-respondido' : 'form-control'}`}
                          multiple
                          value={datosForm.industriasExperiencia}
                          onChange={handleSeleccion}
                          MenuProps={menuProps}
                          inputProps={{
                            name: 'industriasExperiencia',
                            id: 'opciones-multiple',
                          }}
                          >
                          {datosIndustrias.map(opcion =>(
                            <MenuItem key={opcion.id} value={opcion.id}>{opcion.nombre_industria}</MenuItem>
                          ))}
                      </Select>
                      <label className='label-negrita-experiencia'>Industrias seleccionadas: {stringIndustrias}</label>
                    </div>
                    <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                    </form>
                    </Modal> 

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}


              {datos.competencia != null && datos.competencia.length != 0 && 
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                    {datos.competencia.length != 0 && <p className="letra-gris">Competencias:</p> }
                    {datos.competencia.length != 0 && datos.competencia?.map((competenci, index)=>(
                      <p key={"competencias" + index.toString()} className="letra-gris-clara">{competenci.competencia }</p>
                    ))}
                  </div>
 
                    <button id={`menu-perfil-Competencias`} aria-controls="menu-perfil-Competencias" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 8)}>
                        <p className='puntos'>...</p>
                      </button>  
                    <Menu id={`menu-perfil-Competencias`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[8] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <MenuItem onClick={(event) => handleEliminate(event, 8)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 8)}>Editar</MenuItem>
                      </Menu> 
                      <Modal isOpen={menuStatesAbrirModal[8] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                        <div className='form-group'>
                        <label className='label-negrita'>Competencias</label>
                        <label><span className='label-roja'>Seleccionar máximo 5 respuestas, </span></label>
                        <label><span className='label-gris'>priorizando las fortalezas y talentos que mas te representan</span></label>
                        <Select 
                            className='form-control'
                            multiple
                            value={datosForm.competencias}
                            onChange={handleSeleccion}
                            MenuProps={menuProps}
                            inputProps={{
                              name: 'competencias',
                              id: 'opciones-multiple',
                            }}
                            >
                            {datosCompetencia.map(opcion =>(
                              <MenuItem key={opcion.id} value={opcion.id}>{opcion.competencia}</MenuItem>
                            ))}
                            </Select>
                            <label className='label-negrita-experiencia'>Competencias seleccionadas: {stringCompetencias}</label>
                        </div>
                        <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                        </form>
                      </Modal> 
                
                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

            
            </div>}

            {activeInfo === 2 && 
            <div className="info-container">
            {/*FORM VACIO*/}
            {datos.tipoJornada == null && (datos.disponibilidad != null && datos.disponibilidad.length == 0) &&
            datos.tipoModalidad == null && datos.posibilidadCambiarseRegion == null && (datos.disposicion_viajar != null && datos.disposicion_viajar == "") && // revisar disposicion a viajar
                (<div className='contenedor-mayor'>
                  <div className="flex-container"> 
                    <div className='container-comunidad'> 
                      <img className="imagen_porcompletar" alt="Mujer" src={imagen_porcompletar} />
                      <p className="letra-gris-faltacompletar">Un nuevo comienzo</p>
                      <p className="letra-rosada-faltacompletar">Algo para agregar sobre ti</p>
                    </div>
                </div>
              </div>)}

              {(datos.tipoJornada != null || (datos.disponibilidad != null && datos.disponibilidad.length != 0)) &&
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                <div className='contenedor-acotado'>
                    {datos.disponibilidad.length !== 0 && <p className="letra-gris">Disponibilidad para trabajar</p>}
                    {datos.disponibilidad.length !== 0 && (
                      <div>
                        {datos.disponibilidad.map((disponibilidad, index) => (
                          <p key={index} className="letra-gris-clara">
                            {disponibilidad.disponibilidad}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className='barra-vertical'></div>
                  <div className='contenedor-acotado'>
                    {datos.tipoJornada != null && <p className="letra-gris">Disponibilidad de Jornada</p>}
                    {datos.tipoJornada != null && <p className="letra-gris-clara">{datos.tipoJornada.tipoJornada}</p>}
                  </div>
                
                  <button id={`menu-perfil-DisponibilidadJornadaTrabajo`} aria-controls="menu-perfil-DisponibilidadJornadaTrabajo" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 9)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-DisponibilidadJornadaTrabajo`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[9] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                      <MenuItem onClick={(event) => handleEliminate(event, 9)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 9)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[9] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                        <form className='formulario-center'>
                          <div className='form-group'>
                            <label className='label-negrita'>Disponibilidad</label>
                            <label className='label-roja'> Seleccionar máximo 5 respuestas </label>
                            <label className='label-gris'>Indica la modalidad en que estarías dispuesta a asumir un nuevo trabajo, proyecto o asesoría</label>
                            <div className='form-group'>
                              <Select
                                className='form-control'
                                multiple
                                value={datosForm.disponibilidad}
                                onChange={handleSeleccion}
                                MenuProps={menuProps}
                                inputProps={{
                                  name: 'disponibilidad',
                                  id: 'opciones-multiple',
                                }}
                              >
                                {datosDisponibilidad.map((disponibilidad) => (
                                  <MenuItem key={disponibilidad.id} value={disponibilidad.id}>
                                    {disponibilidad.disponibilidad}
                                  </MenuItem>
                                ))}
                              </Select>
                              <label className='label-negrita-experiencia'>Disponibilidad seleccionada: {stringDisponibilidad}</label>
                            </div>
                          </div>
                          <div className='botones'>
                            <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                          </div>
                        </form>
                      </Modal>
                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              {(datos.tipoModalidad != null) &&
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                    {datos.tipoModalidad != null && <p className="letra-gris">Modalidad de preferencia</p>}
                    {datos.tipoModalidad != null && <p className="letra-gris-clara">{datos.tipoModalidad.tipoModalidad}</p>}
                  </div>

                  <button id={`menu-perfil-ModalidadPreferencia`} aria-controls="menu-perfil-ModalidadPreferencia" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 10)}>
                      <p className='puntos'>...</p>
                    </button>  
                    <Menu id={`menu-perfil-ModalidadPreferencia`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[10] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <MenuItem onClick={(event) => handleEliminate(event, 10)}>Eliminar</MenuItem>
                      <MenuItem onClick={(event)=>handleClickAbrirModal(event, 10)}>Editar</MenuItem>
                    </Menu>
                    <Modal isOpen={menuStatesAbrirModal[10] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                    <form className='formulario-center'>
                    <div className='form-group'>
                      <label className='label-negrita'>Modalidad de preferencia</label>

                      <select
                        className='form-control'
                        name='id_modalidad'
                        value={datosForm.id_modalidad || ''}
                        onChange={(event) => handleDropdownChange(event)}>
                        <option value="">Escoga una opción:</option>
                        {datosModalidades.map(modalidad => (
                          <option key={modalidad.id} value={modalidad.id}>
                            {modalidad.tipoModalidad}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                    </div>
                    </form>
                    </Modal>

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}
              
              {(datos.posibilidadCambiarseRegion != null || (datos.disposicion_viajar != null && datos.disposicion_viajar != "")) &&
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='contenedor-acotado'>
                    {datos.disposicion_viajar != null && <p className="letra-gris">Disposición a viajar</p>}
                    {datos.disposicion_viajar != null && datos.disposicion_viajar == true && <p className="letra-gris-clara">Si</p>}
                    {datos.disposicion_viajar != null && datos.disposicion_viajar == false && <p className="letra-gris-clara">No</p>}
                  </div>
                  <div className='barra-vertical'></div>
                  <div className='contenedor-acotado'>
                    {datos.posibilidadCambiarseRegion != null && <p className="letra-gris">Posibilidad de cambiarse de región</p>}
                    {datos.posibilidadCambiarseRegion != null && <p className="letra-gris-clara">{datos.posibilidadCambiarseRegion.posibilidad}</p>}
                  </div>

                  <button id={`menu-perfil-PosibilidadViajarRegion`} aria-controls="menu-perfil-PosibilidadViajarRegion" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 11)}>
                      <p className='puntos'>...</p>
                    </button>  
                    <Menu id={`menu-perfil-PosibilidadViajarRegion`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[11] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <MenuItem onClick={(event) => handleEliminate(event, 11)}>Eliminar</MenuItem>
                      <MenuItem onClick={(event)=>handleClickAbrirModal(event, 11)}>Editar</MenuItem>
                    </Menu>
                    <Modal isOpen={menuStatesAbrirModal[11] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                    <form className='formulario-center'>
                    <div className='form-group'>
                    <label htmlFor="cambioRegion" className="label-negrita">Posibilidad de cambiarse de región</label>
                      <select
                        id="cambioRegion"
                        value={datosForm.id_posibilidad_cambiarse_region || ''}
                        name="id_posibilidad_cambiarse_region"
                        onChange={handleDropdownChange}
                        className="form-control">
                        <option value=''>Escoge una opción</option>
                        <option value={1}>Si</option>
                        <option value={2}>No</option>
                      </select>
                    </div><div className='form-group'>
                      <label htmlFor="disposicionViajar" className="label-negrita">Disposición a viajar</label>
                      <select
                        id="disposicionViajar"
                        name="disposicion_viajar"
                        value={datosForm.disposicion_viajar}
                        onChange={handleDropdownChange}
                        className="form-control">
                        <option value=''>Escoge una opción</option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                    <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                    </form>
                    </Modal>

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

            </div>}

          {activeInfo === 3 && 
            <div className="info-container">
          {/*FORM VACIO*/}
          {datos.idiomas != null && datos.idiomas.length == 0 && datos.factor === null && datos.regionCompromiso === null &&
          datos.nombrePuebloOriginario === null && datos.personalidad === null && datos.redesSociales === null &&
          datos.conocioWOT === null &&
                (<div className='contenedor-mayor'>
                  <div className="flex-container"> 
                    <div className='container-comunidad'> 
                      <img className="imagen_porcompletar" alt="Mujer" src={imagen_porcompletar} />
                      <p className="letra-gris-faltacompletar">Un nuevo comienzo</p>
                      <p className="letra-rosada-faltacompletar">Algo para agregar sobre ti</p>
                  </div>
              </div>
            </div>)}

            {datos.idiomas != null && datos.idiomas.length != 0 &&
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                    {datos.idiomas.length != 0 && <p className="letra-gris">Dominio de idioma</p>}
                    {datos.idiomas?.map((idioma, index)=>(
                    <p key={"idioma" + index.toString()} className="letra-gris-clara">{idioma.nombre}</p>))}
                  </div>
                  <button id={`menu-perfil-DominioIdioma`} aria-controls="menu-perfil-DominioIdioma" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 12)}>
                      <p className='puntos'>...</p>
                    </button>  
                    <Menu id={`menu-perfil-DominioIdioma`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[12] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <MenuItem onClick={(event) => handleEliminate(event, 12)}>Eliminar</MenuItem>
                      <MenuItem onClick={(event)=>handleClickAbrirModal(event, 12)}>Editar</MenuItem>
                    </Menu>
                    <Modal isOpen={menuStatesAbrirModal[12] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                    <form className='formulario-center'>
                    <div className='form-group'>
                      <label htmlFor="dominioIdioma" className="label-negrita">Dominio de idioma medio-avanzado:</label>
                      <select
                        className='form-control'
                        name='idiomas'
                        value={datosForm.idiomas || ''}
                        onChange={(event) => handleArray(event)}>
                        <option value="">Selecciona una opcion:</option>
                        {datosIdiomas.map(idioma => (
                          <option key={idioma.id} value={idioma.id}>
                            {idioma.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                    </form>
                    </Modal>

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              {datos.factor != null &&
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                    {datos.factor != null && <p className="letra-gris">¿Tiene algún factor de inclusión laboral?</p>}
                    {datos.factor != null && <p className="letra-gris-clara">{datos.factor}</p>}
                  </div>

                  <button id={`menu-perfil-FactorInclusion`} aria-controls="menu-perfil-FactorInclusion" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 13)}>
                      <p className='puntos'>...</p>
                    </button>  
                    <Menu id={`menu-perfil-FactorInclusion`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[13] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <MenuItem onClick={(event) => handleEliminate(event, 13)}>Eliminar</MenuItem>
                      <MenuItem onClick={(event)=>handleClickAbrirModal(event, 13)}>Editar</MenuItem>
                    </Menu>
                    <Modal isOpen={menuStatesAbrirModal[13] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                    <form className='formulario-center'>
                    <div className='form-group'>
                      <label htmlFor="inclusionLaboral" className="label-negrita">Tiene algún factor de inclusión laboral</label>
                      <select
                        id="inclusionLaboral"
                        name="factor"
                        onChange={(event) => handleAdicional(event)}
                        value={selectedFactor}
                        className="form-control">
                        <option value=''>Selecciones una opción</option>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                      </select>
                    </div>

                    {mostrarFactor&& (
                      <div className='form-group'>
                        <label htmlFor="inclusionLaboralText" className="label-negrita">¿Cual es su factor de inclusión laboral?</label>
                        <input
                          type="text"
                          id="inclusionLaboralText"
                          name="factor"
                          value={datos.factor || ''}
                          onChange={handleInputChange}
                          className="form-control" />
                      </div>
                    )}
                    <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                    </form>
                    </Modal>

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              {datos.regionCompromiso != null && // SelectedCompromiso("Si");
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                    {datos.regionCompromiso != null && <p className="letra-gris">¿Tiene relación con alguna región del país?</p>}
                    {datos.regionCompromiso != null && selectedCompromiso == "Si" && <p className="letra-gris-clara">{datos.regionCompromiso.nombre}</p>}
                    {datos.regionCompromiso != null && selectedCompromiso == "No" && <p className="letra-gris-clara">No</p>}
                  </div>
                  <button id={`menu-perfil-RelacionRegion`} aria-controls="menu-perfil-RelacionRegion" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 14)}>
                      <p className='puntos'>...</p>
                    </button>  
                    <Menu id={`menu-perfil-RelacionRegion`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[14] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <MenuItem onClick={(event) => handleEliminate(event, 14)}>Eliminar</MenuItem>
                      <MenuItem onClick={(event)=>handleClickAbrirModal(event, 14)}>Editar</MenuItem>
                    </Menu>
                    <Modal isOpen={menuStatesAbrirModal[14] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                    <form className='formulario-center'>
                    <div className='form-group'>
                      <label htmlFor="compromisoRegion" className="label-negrita">Tiene algún compromiso con alguna región del pais</label>
                      <select
                        id="compromisoRegion"
                        name="compromiso"
                        onChange={(event) => handleAdicional(event)}
                        className="form-control"
                        value={selectedCompromiso}>
                        
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                      </select>
                    </div>
                    {mostrarCompromiso && (
                      <div className='form-group'>
                      <label className='label-negrita'>Selecciona la region con cual estas comprometida:</label>
                          <select
                          className='form-control'
                          value={datosForm.id_region_con_compromiso}
                          name='id_region_con_compromiso'
                          onChange={(event) => handleDropdownChange(event)}>
                            <option value="">Selecciona tu region:</option>
                            {datosCompromiso.map(region=> (
                              <option key={region.id} value={region.id}>
                                {region.nombre}
                              </option>
                            ))}
                          </select>
                      </div>
                    )}
                    <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                    </form>
                    </Modal>
                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              {(datos.nombrePuebloOriginario != null) &&
                  <div className='contenedor-mayor'>
                    <div className="flex-container"> 
                      <div className='container-comunidad'> 
                      {datos.nombrePuebloOriginario != null && <p className="letra-gris">¿Se identifica con algún pueblo originario?</p>}
                      {datos.nombrePuebloOriginario != null && <p className="letra-gris-clara">{datos.nombrePuebloOriginario}</p>}
                      </div>

                      <button id={`menu-perfil-IdentificaPuebloOriginario`} aria-controls="menu-perfil-IdentificaPuebloOriginario" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 15)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-IdentificaPuebloOriginario`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[15] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                      <MenuItem onClick={(event) => handleEliminate(event, 15)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 15)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[15] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                      <div className='form-group'>
                        <label htmlFor="puebloIndigena" className="label-negrita">Se identifica con algún pueblo originario</label>
                        <select
                          id="puebloIndigena"
                          name="pueblo"
                          value={selectedPueblo}
                          onChange={(event) => handleAdicional(event)}
                          className="form-control">
                          <option value="No" >No</option>
                          <option value="Si">Si</option>
                        </select>
                      </div>
                      {mostrarPueblo&& (
                        <div className='form-group'>
                          <label htmlFor="puebloIndigenaEleccion" className="label-negrita">Con que pueblo originario se identifica?</label>
                          <input
                            type="text"
                            id="puebloIndigenaEleccion"
                            name="nombrePuebloOriginario"
                            value={datos.nombrePuebloOriginario || ''}
                            onChange={handleInputChange}
                            className="form-control" />
                        </div> )}
                        <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                        </form>
                      </Modal>

                    </div>
                    <div className='linea-gris-mediana'></div>
                  </div>}

              {(datos.personalidad != null) &&
                  <div className='contenedor-mayor'>
                    <div className="flex-container"> 
                      <div className='container-comunidad'> 
                      {datos.personalidad != null && <p className="letra-gris">Respuesta formulario personalidad</p>}
                      {datos.personalidad != null && <p className="letra-gris-clara">{datos.personalidad.personalidad}</p>}
                      </div>
                      <button id={`menu-perfil-FormularioPersonalidad`} aria-controls="menu-perfil-FormularioPersonalidad" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 16)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-FormularioPersonalidad`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[16] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                      <MenuItem onClick={(event) => handleEliminate(event, 16)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 16)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[16] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                        <div className='form-group'>
                          <label htmlFor="personalidad" className="label-negrita">Respuesta formulario de personalidad</label>
                          <label className='label-gris'>https://www.humanmetrics.com/personalidad/test</label>
                          <select
                            className='form-control'
                            name='id_personalidad'
                            value={datosForm.id_personalidad || ''}
                            onChange={(event) => handleDropdownChange(event)}>
                            <option value="">Selecciona una opcion:</option>
                            {datosPersonalidad.map(personalidad => (
                              <option key={personalidad.id} value={personalidad.id}>
                                {personalidad.personalidad}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                        </form>
                      </Modal>

                    </div>
                    <div className='linea-gris-mediana'></div>
                  </div>}

              {(datos.redesSociales != null) &&
                <div className='contenedor-mayor'>
                  <div className="flex-container"> 
                    <div className='container-comunidad'> 
                    {datos.redesSociales != null && <p className="letra-gris">Redes sociales</p>}
                    {datos.redesSociales != null && <p className="letra-gris-clara">{datos.redesSociales}</p>}
                    </div>

                    <button id={`menu-perfil-RedesSociales`} aria-controls="menu-perfil-RedesSociales" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 17)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-RedesSociales`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[17] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                      <MenuItem onClick={(event) => handleEliminate(event, 17)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 17)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[17] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                        <div className='form-group'>
                          <label htmlFor="linkedin" className="label-negrita">Perfil de linkedin</label>
                          <label className='label-gris2'>Puede ser el perfil de RRSS que quieras compartir con nosotros.</label>
                          <input
                            type="text"
                            id="linkedin"
                            name="redesSociales"
                            value={datos.redesSociales || ''}
                            onChange={handleInputChange}
                            className="form-control" />
                        </div>
                        <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                        </form>
                      </Modal>

                  </div>
                  <div className='linea-gris-mediana'></div>
              </div>}
    
              {(datos.conocioWOT != null) &&
                <div className='contenedor-mayor'>
                  <div className="flex-container"> 
                    <div className='container-comunidad'> 
                    {datos.conocioWOT != null && <p className="letra-gris">¿Cómo conoció a wot?</p>}
                    {datos.conocioWOT != null && <p className="letra-gris-clara">{datos.conocioWOT.conocio}</p>}
                    </div>
                    
                    <button id={`menu-perfil-ConocioWOT`} aria-controls="menu-perfil-ConocioWOT" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 18)}>
                        <p className='puntos'>...</p>
                      </button>  
                      <Menu id={`menu-perfil-ConocioWOT`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[18] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                      <MenuItem onClick={(event) => handleEliminate(event, 18)}>Eliminar</MenuItem>
                        <MenuItem onClick={(event)=>handleClickAbrirModal(event, 18)}>Editar</MenuItem>
                      </Menu>
                      <Modal isOpen={menuStatesAbrirModal[18] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                      <form className='formulario-center'>
                        <div className='form-group'>
                          <label htmlFor="wot" className="label-negrita">Cómo conocío WoT</label>
                          <select
                            className='form-control'
                            value={datosForm.id_conocio_wot || ''}
                            name='id_conocio_wot'
                            onChange={(event) => handleDropdownChange(event)}>
                              <option value="">Selecciona una opcion:</option>
                              {datosWot.map( wot=> (
                                <option key={wot.id} value={wot.id}>
                                  {wot.conocio}
                                </option>
                              ))}
                            </select>
                        </div>
                        <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                        </form>
                      </Modal>

                  </div>
                  <div className='linea-gris-mediana'></div>
                </div>}

            </div>}
          
            {activeInfo === 4 &&
            <div className="info-container">   
            {/*FORM VACIO*/}

            {datos.contactos_verificacion != null && datos.contactos_verificacion.length == 0 && 
                  (<div className='contenedor-mayor'>
                    <div className="flex-container"> 
                      <div className='container-comunidad'> 
                        <img className="imagen_porcompletar" alt="Mujer" src={imagen_porcompletar} />
                        <p className="letra-gris-faltacompletar">Un nuevo comienzo</p>
                        <p className="letra-rosada-faltacompletar">Algo para agregar sobre ti</p>
                    </div>
                </div>
              </div>)}

              {datos.contactos_verificacion != null && datos.contactos_verificacion.length >= 1 &&
                <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                  {<p className="letra-gris-pregunta">¿Tienes algún contacto de referencia con quien se pueda validar la información?</p>}
                  {<p className="letra-gris-clara">Si</p>}
                  </div>

                  <button id={`menu-perfil-ContactoReferencia1`} aria-controls="menu-perfil-ContactoReferencia1" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 19)}>
                    <p className='puntos'>...</p>
                  </button>  
                  <Menu id={`menu-perfil-ContactoReferencia1`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[19] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                  <MenuItem onClick={(event) => handleEliminate(event, 19)}>Eliminar</MenuItem>
                    <MenuItem onClick={(event)=>handleClickAbrirModal(event, 19)}>Editar</MenuItem>
                  </Menu>
                  <Modal isOpen={menuStatesAbrirModal[19] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                  <form className='formulario-center'>
                  <div className='form-group'>
                    <label className='label-negrita'>Tiene algún contacto para validar la información</label>
                    <select
                      name="contacto1"
                      className='form-control'
                      onChange={handleAdicional}>
                      <option value=''>Selecciona una opcion:</option>
                      <option value=''>No</option>
                      <option value='1'>Si</option>
                    </select>
                  </div>
                  {mostrarContacto1 && (
                    <div><div className='form-group'>
                        <label className="label-negrita">Nombre Completo</label>
                        <input
                          type="text"
                          id="contacto_nombre1"
                          name="contacto_nombre1"
                          onChange={handleContactos}
                          className="form-control" />
                      </div><div className='form-group'>
                          <label className="label-negrita">Celular</label>
                          <input
                            type="text"
                            id="contacto_celular1"
                            name="contacto_celular1"
                            onChange={handleContactos}
                            className="form-control" />
                      </div> <div className='form-group'>
                          <label className="label-negrita">Mail</label>
                          <input
                            type="text"
                            id="contacto_mail1"
                            name="contacto_mail1"
                            onChange={handleContactos}
                            className="form-control" />
                        </div></div>
                    )}
                    <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                    </form>
                  </Modal>

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}


              { datos.contactos_verificacion != null && datos.contactos_verificacion.length >= 1 &&
              <div className='contenedor-mayor'>
                <div className="flex-container-responsive">
                <div className='contenedor-acotado-chico-responsive'>
                    {<p className="letra-gris">Nombre contacto</p>}
                    <div></div>
                    {datos.contactos_verificacion[0].nombre != null && <p className="letra-gris-clara">{datos.contactos_verificacion[0].nombre}</p>}
                    <div></div>
                    {<p className="letra-gris">Celular</p>}
                    {datos.contactos_verificacion[0].telefono != null && <p className="letra-gris-clara">{datos.contactos_verificacion[0].telefono}</p>}
                    <div></div>
                    {<p className="letra-gris">Mail</p>}
                    {datos.contactos_verificacion[0].email != null && <p className="letra-gris-clara">{datos.contactos_verificacion[0].email}</p>}
                  </div>
                  </div>
                <div className="flex-container-contactos"> 
                  <div className='contenedor-acotado-chico'>
                    {<p className="letra-gris">Nombre contacto</p>}
                    {datos.contactos_verificacion[0].nombre != null && <p className="letra-gris-clara">{datos.contactos_verificacion[0].nombre}</p>}
                  </div>
                  <div className='barra-vertical'></div>
                  <div className='contenedor-acotado-chico'>
                    {<p className="letra-gris">Celular</p>}
                    {datos.contactos_verificacion[0].telefono != null && <p className="letra-gris-clara">{datos.contactos_verificacion[0].telefono}</p>}
                  </div>
                  <div className='barra-vertical'></div>
                  <div className='contenedor-acotado-chico'>
                    {<p className="letra-gris">Mail</p>}
                    {datos.contactos_verificacion[0].email != null && <p className="letra-gris-clara">{datos.contactos_verificacion[0].email}</p>}
                  </div>
                  {/* <div className='barra-vertical'></div>
                  <div className='contenedor-acotado-chico'>
                    
                  </div>
                  <div className='barra-vertical'></div>
                  <div className='contenedor-acotado-chico'>
                    
                  </div> */}
                  
                  <button id={`menu-perfil-ContactoReferencia1Datos`} aria-controls="menu-perfil-ContactoReferencia1Datos" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 20)}>
                    <p className='puntos'>...</p>
                  </button>  
                  <Menu id={`menu-perfil-ContactoReferencia1Datos`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[20] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <MenuItem onClick={(event) => handleEliminate(event, 19)}>Eliminar</MenuItem>
                    <MenuItem onClick={(event)=>handleClickAbrirModal(event, 20)}>Editar</MenuItem>
                  </Menu>
                  <Modal isOpen={menuStatesAbrirModal[20] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                  <form className='formulario-center'>
                  <div className='form-group'>
                    <label className='label-negrita'>Tiene algún contacto para validar la información</label>
                    <select
                      name="contacto1"
                      className='form-control'
                      onChange={handleAdicional}>
                      <option value=''>Selecciona una opcion:</option>
                      <option value=''>No</option>
                      <option value='1'>Si</option>
                    </select>
                  </div>
                  {mostrarContacto1 && (
                    <div><div className='form-group'>
                        <label className="label-negrita">Nombre Completo</label>
                        <input
                          type="text"
                          id="contacto_nombre1"
                          name="contacto_nombre1"
                          onChange={handleContactos}
                          className="form-control" />
                      </div><div className='form-group'>
                          <label className="label-negrita">Celular</label>
                          <input
                            type="text"
                            id="contacto_celular1"
                            name="contacto_celular1"
                            onChange={handleContactos}
                            className="form-control" />
                      </div> <div className='form-group'>
                          <label className="label-negrita">Mail</label>
                          <input
                            type="text"
                            id="contacto_mail1"
                            name="contacto_mail1"
                            onChange={handleContactos}
                            className="form-control" />
                        </div></div>
                    )}
                    <div className='botones'>
                          <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                        </div>
                    </form>

                  </Modal>

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}
              <div className="linea_gris"></div>

              { datos.contactos_verificacion != null && datos.contactos_verificacion.length == 2 &&
              
                <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='container-comunidad'> 
                  {<p className="letra-gris-pregunta">¿Tienes otro contacto de referencia con quien se pueda validar la información?</p>}
                  {<p className="letra-gris-clara">Si</p>}
                  </div>

                  <button id={`menu-perfil-ContactoReferencia2`} aria-controls="menu-perfil-ContactoReferencia2" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 21)}>
                    <p className='puntos'>...</p>
                  </button>  
                  <Menu id={`menu-perfil-ContactoReferencia2`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[21] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                  <MenuItem onClick={(event) => handleEliminate(event, 20)}>Eliminar</MenuItem>
                    <MenuItem onClick={(event)=>handleClickAbrirModal(event, 21)}>Editar</MenuItem>
                  </Menu>
                  <Modal isOpen={menuStatesAbrirModal[21] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                  <form className='formulario-center'>
                  <div className='form-group'>
                    <label className='label-negrita'>Tiene otro contacto para validar la información</label>
                      <select
                        name="contacto2"
                        className='form-control'
                        onChange={handleAdicional}>
                        <option value=''>Selecciona una opcion:</option>
                        <option value=''>No</option>
                        <option value='1'>Si</option>
                      </select></div>
                      {mostrarContacto2 && (
                    <div><div className='form-group'>
                        <label className="label-negrita">Nombre Completo</label>
                        <input
                          type="text"
                          id="contacto_nombre2"
                          name="contacto_nombre2"
                          onChange={handleContactos}
                          className="form-control" />
                      </div><div className='form-group'>
                          <label className="label-negrita">Celular</label>
                          <input
                            type="text"
                            id="contacto_celular2"
                            name="contacto_celular2"
                            onChange={handleContactos}
                            className="form-control" />
                        </div>
              
                        <div className='form-group'>
                          <label className="label-negrita">Mail</label>
                          <input
                            type="text"
                            id="contacto_mail2"
                            name="contacto_mail2"
                            onChange={handleContactos}
                            className="form-control" />
                        </div></div>)}
                    <div className='botones'>
                      <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                    </div>
                   </form>
                  </Modal>

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}

              {datos.contactos_verificacion != null && datos.contactos_verificacion.length == 2 &&
              <div className='contenedor-mayor'>
                <div className="flex-container-responsive"> 
                  <div className='contenedor-acotado-chico-responsive'>
                      {<p className="letra-gris">Nombre contacto</p>}
                      {datos.contactos_verificacion[1].nombre != null && <p className="letra-gris-clara">{datos.contactos_verificacion[1].nombre}</p>}
                      {<p className="letra-gris">Celular</p>}
                      {datos.contactos_verificacion[1].telefono != null && <p className="letra-gris-clara">{datos.contactos_verificacion[1].telefono}</p>}
                      {<p className="letra-gris">Mail</p>}
                      {datos.contactos_verificacion[1].email != null && <p className="letra-gris-clara">{datos.contactos_verificacion[1].email}</p>}
                    </div>
                  </div>
                <div className="flex-container-contactos">
                  <div className='contenedor-acotado-chico'>
                    {<p className="letra-gris">Nombre contacto</p>}
                    {datos.contactos_verificacion[1].nombre != null && <p className="letra-gris-clara">{datos.contactos_verificacion[1].nombre}</p>}
                  </div>
                  <div className='barra-vertical'></div>
                  <div className='contenedor-acotado-chico'>
                    {<p className="letra-gris">Celular</p>}
                    {datos.contactos_verificacion[1].telefono != null && <p className="letra-gris-clara">{datos.contactos_verificacion[1].telefono}</p>}
                  </div>
                  <div className='barra-vertical'></div>
                  <div className='contenedor-acotado-chico'>
                    {<p className="letra-gris">Mail</p>}
                    {datos.contactos_verificacion[1].email != null && <p className="letra-gris-clara">{datos.contactos_verificacion[1].email}</p>}
                  </div>

                  <button id={`menu-perfil-ContactoReferencia2Datos`} aria-controls="menu-perfil-ContactoReferencia2Datos" className='button-rosado-puntos' variant="contained" color="primary" onClick={(event)=>handleClickAbrirSubMenu(event, 22)}>
                    <p className='puntos'>...</p>
                  </button>  
                  <Menu id={`menu-perfil-ContactoReferencia2Datos`} anchorEl={anchorEl} open={menuStatesAbrirSubMenu[22] || false} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <MenuItem onClick={(event) => handleEliminate(event, 20)}>Eliminar</MenuItem>
                    <MenuItem onClick={(event)=> handleClickAbrirModal(event, 22)}>Editar</MenuItem>
                  </Menu>
                  <Modal isOpen={menuStatesAbrirModal[22] || false} onRequestClose={handleSubmit} className='estilo-modal' contentLabel="Confirmación de Borrado">
                  <form className='formulario-center'>
                  <div className='form-group'>
                    <label className='label-negrita'>Tiene otro contacto para validar la información</label>
                      <select
                        name="contacto2"
                        className='form-control'
                        onChange={handleAdicional}>
                        <option value=''>Selecciona una opcion:</option>
                        <option value=''>No</option>
                        <option value='1'>Si</option>
                      </select></div>
                      {mostrarContacto2 && (
                    <div><div className='form-group'>
                        <label className="label-negrita">Nombre Completo</label>
                        <input
                          type="text"
                          id="contacto_nombre2"
                          name="contacto_nombre2"
                          onChange={handleContactos}
                          className="form-control" />
                      </div><div className='form-group'>
                          <label className="label-negrita">Celular</label>
                          <input
                            type="text"
                            id="contacto_celular2"
                            name="contacto_celular2"
                            onChange={handleContactos}
                            className="form-control" />
                        </div>
              
                        <div className='form-group'>
                          <label className="label-negrita">Mail</label>
                          <input
                            type="text"
                            id="contacto_mail2"
                            name="contacto_mail2"
                            onChange={handleContactos}
                            className="form-control" />
                        </div></div>)}
                      <div className='botones'>
                        <button type="button" onClick={handleSubmit} className='editar-button'>Guardar Cambios</button>
                      </div>
                   </form>
                  </Modal>

                </div>
                <div className='linea-gris-mediana'></div>
              </div>}


            </div>}
        </div>
        
            
        <div className="rectangulo-arriba">

            <h3 className="info-personal">Información Personal</h3>
            
            <div className='linea-gris-corta'></div>
            {/*datos.rut != null && */
              <div>
                <div className="texto-centrado">
                  <p className="dato-nombre">RUT</p>
                  <p className="asterisco-rosa">*</p>
                  {datos.rut != null && <p className="dato-personal">{datos.rut}</p>}
                  {datos.rut == null && <p className="dato-faltacompletar">Falta completar</p>}
                </div>
                <div className='linea-gris-corta'></div>
              </div>
            }

            {/*datos.celular != null && */
              <div>
                <div className="texto-centrado">
                  <p className="dato-nombre">Celular</p>
                  <p className="asterisco-rosa">*</p>
                  {datos.celular != null && <p className="dato-personal">{datos.celular}</p>}
                  {datos.celular == null && <p className="dato-faltacompletar">Falta completar</p>}
                </div>
                <div className='linea-gris-corta'></div>
              </div>
            }

            {/*datos.mail != null &&*/ 
              <div>
                <div className="texto-centrado">
                  <p className="dato-nombre">Mail</p>
                  <p className="asterisco-rosa">*</p>
                  {datos.mail != null && <p className="dato-personal">{datos.mail}</p>}
                  {datos.mail == null && <p className="dato-faltacompletar">Falta completar</p>}
                </div>
                <div className='linea-gris-corta'></div>
              </div>
            }

            {/*datos.paisDomicilio != null && */
              <div>
                <div className="texto-centrado">
                  <p className="dato-nombre">País de domicilio</p>
                  <p className="asterisco-rosa">*</p>
                  {datos.paisDomicilio != null && <p className="dato-personal">{datos.paisDomicilio.pais}</p>}
                  {datos.paisDomicilio == null && <p className="dato-faltacompletar">Falta completar</p>}
                </div>
                <div className='linea-gris-corta'></div>
              </div>
            }

            {/*datos.intereses != null && */
              <div>
                <div className="texto-centrado">
                  <p className="dato-nombre">3 Intereses personales</p>
                  <p className="asterisco-rosa">*</p>
                  {datos.intereses != null && <p className="dato-personal">{datos.intereses}</p>}
                  {datos.intereses == null && <p className="dato-faltacompletar">Falta completar</p>}
                </div>
                <div className='linea-gris-corta'></div>
              </div>
            }
            
            {
              <div>
              <div className="texto-centrado">
                {cv != "" && <a href={cv} target="_blank" rel="noopener noreferrer" className="dato-cv">Curriculum de {datos.nombre}</a>}
              </div>
              <div className='linea-gris-corta'></div>
            </div>
            }

            <h3 className="ayuda">Si necesitas ayuda escribe a help@womantalent.cl</h3>
        
            <div className='linea-gris-corta'></div>

          </div>

          {/* <div className="segundo-rectangulo"> */}
            {/* <h3 className="otras-comunidades">Otras comunidades</h3> */}

            {/* {communities != null && communities.length == 0 && <p className="dato-faltacompletar">Debes completar tus datos para ver esta sección</p>} */}

            {/* {communities.map((community) => (

              <div className='contenedor-mayor2'>
                <div className="flex-container"> 
                  <div className="sphere"></div>
                  <div className='container-comunidad'> 
                    <p className="nombre-comunidad">{community.name}</p>
                    <p className="cantidad-seguidores">{community.followers} seguidores</p>
                    <button
                      className={getButtonClass(button1Active)}
                      onClick={() => handleJoinCommunity(community.id)}
                    >
                      {button1Active ? 'Siguiendo' : 'Seguir'}
                    </button>
                  </div>
                </div>
                <div className='linea-gris-corta'></div>
              </div>
            ))} */}
            {/* </div>  */}
          </div>
        </div>
      </div>

      /* 
      <div>
        <div className="rectangulo-rosado">

        </div>

        <h3>¿Deseas cambiar tu contraseña?</h3>
        <button onClick={() => setModalContraIsOpen(true)} className="button-pink" >Cambiar contraseña</button>
        <p></p>

        <h3>¿Deseas eliminar tu cuenta?</h3>
        <button className="button-pink" onClick={deleteUser} id='eliminar_cuenta_button'>Eliminar cuenta</button>
        <p></p>

      </div>
      */

    )}
  </div><div>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleSubmit}
        contentLabel="Editar Datos"
        className='estilo-modal'
      >
        
      <div>
          <button className='boton-salir' onClick={handleSubmit}>x</button>
          <p className={`texto-elipse-1 ${destacado1}`}>Información personal</p>
          <p className={`texto-elipse-2 ${destacado2}`}>Información profesional</p>
          <p className={`texto-elipse-3 ${destacado3}`}>Disponibilidad</p>
          <p className={`texto-elipse-4 ${destacado4}`}>Información adicional</p>
          <p className={`texto-elipse-5 ${destacado5}`}>Referencias</p>
          <div className={`elipse ${bordeElipse1}`}>1</div>
          <div className={`elipse2 ${bordeElipse2}`}>2</div>
          <div className={`elipse3 ${bordeElipse3}`}>3</div>
          <div className={`elipse4 ${bordeElipse4}`}>4</div>
          <div className={`elipse5 ${bordeElipse5}`}>5</div>
          
          <form onSubmit={handleSubmit} className='formulario-modal'>
            {currentStep===1 &&(
              <>
               <div className='columna-responsive'>
                <div className='form-group'>
                  <label className="label-negrita">Nombre</label>
                  <label className='label-gris'>Ej: Josefina</label>
                  <input
                    type="text"
                    id="name"
                    name="nombre"
                    value={datos.nombre || ''}
                    onChange={handleInputChange}
                    className={`form-control ${isFieldEmpty(datos.nombre) ? 'form-control-no-respondido' : 'form-control'}`} />
                </div>

                <div className='form-group'>
                  <label className='label-negrita'>Apellido:</label>
                  <label className='label-gris'>Ej: Lagos</label>
                  <input
                    type="text"
                    id="apellido"
                    name="apellido"
                    value={datos.apellido || ''}
                    onChange={handleInputChange}
                    className={`form-control ${isFieldEmpty(datos.apellido) ? 'form-control-no-respondido' : 'form-control'}`} />
                </div>

                <div className='form-group'>
                  <label htmlFor="rut" className="label-negrita">Rut</label>
                  <label className='label-gris'>Ej: 23245245-K</label>
                  <input
                    type="text"
                    id="rut"
                    name="rut"
                    value={datos.rut || ''}
                    onChange={handleInputChange}
                    className={`form-control ${isFieldEmpty(datos.rut) ? 'form-control-no-respondido' : 'form-control'}`} />
                  {!rutValido && (
                    <label className='label-roja'>RUT inválido</label>
                  )}
                </div>

                <div className='form-group'>
                  <label htmlFor="celular" className="label-negrita">Celular:</label>
                  <label className='label-gris'>Ej: 962806381</label>
                  <input
                    type="tel"
                    id="celular"
                    name="celular"
                    value={datos.celular || ''}
                    onChange={handleInputChange}
                    className={`form-control ${isFieldEmpty(datos.celular) ? 'form-control-no-respondido' : 'form-control'}`} />
                  {!celValido && (
                    <label className='label-roja'>Celular inválido</label>
                  )}
                </div>

                <div className='form-group'>
                  <label htmlFor="Intereses" className="label-negrita">Intereses </label>
                  <label className='label-gris-interes'>Señala tus principales intereses personales o profesionales.</label>
                  <label className='label-gris-interes'>Máximo 3 palabras separadas por coma.</label>
                  <input
                    type="text"
                    id="Intereses"
                    name="intereses"
                    value={datos.intereses || ''}
                    onChange={(event) => handleInputChange(event)}
                    className={`form-control ${isFieldEmpty(datos.intereses) ? 'form-control-no-respondido' : 'form-control'}`} />
                </div>

                <div className='form-group'>
                  <label className='label-negrita'>País de domicilio</label>
                  <select
                    value={datosForm.id_pais_domicilio || ''}
                    className={`form-control ${isFieldEmpty(datosForm.id_pais_domicilio) ? 'form-control-no-respondido' : 'form-control'}`}
                    name='id_pais_domicilio'
                    onChange={(event) => {
                      setMostrarRegion(event.target.value === '1');
                      handleDropdownChange(event);
                    }}>
                    <option value=''>Escoga una opción</option>
                    {datosPaises
                      .slice()
                      .sort((a, b) => a.pais.localeCompare(b.pais))
                      .map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.pais}
                        </option>
                      ))}
                  </select>
                </div>
                {mostrarRegion && (datosForm.regionActualDomicilio == null) && (
                    <div className='form-group'>
                      <label className='label-negrita'>Región</label>
                      <select
                        className={`form-control ${isFieldEmpty(datosForm.regionActualDomicilio) ? 'form-control-no-respondido' : 'form-control'}`}
                        value={datosForm.regionActualDomicilio || ''}
                        name='region_domicilio'
                        onChange={(event) => handleDropdownChangeRegion(event)}>
                        <option value=''>Selecciona tu región:</option>
                        {datosCompromiso
                          .slice()
                          .sort((a, b) => a.nombre.localeCompare(b.nombre))
                          .map((region) => (
                            <option key={region.id} value={region.id}>
                              {region.nombre}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                  {mostrarRegion && datosForm.regionActualDomicilio !== null && (
                    <div className='form-group'>
                      <label className='label-negrita'>Región</label>
                      <select
                        className={`form-control ${isFieldEmpty(datosForm.regionActualDomicilio.id) ? 'form-control-no-respondido' : 'form-control'}`}
                        value={datosForm.regionActualDomicilio.id || ''}
                        name='region_domicilio'
                        onChange={(event) => handleDropdownChangeRegion(event)}>
                        <option value=''>Selecciona tu región:</option>
                        {datosCompromiso
                          .slice()
                          .sort((a, b) => a.nombre.localeCompare(b.nombre))
                          .map((region) => (
                            <option key={region.id} value={region.id}>
                              {region.nombre}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
              </div>
              <div className='columnas margin-columnas-top'>
              <div className='columna-izquierda'>
                <div className='form-group'>
                  <label className="label-negrita">Nombre</label>
                  <label className='label-gris'>Ej: Josefina</label>
                  <input
                    type="text"
                    id="name"
                    name="nombre"
                    value={datos.nombre || ''}
                    onChange={handleInputChange} 
                    className={`form-control ${isFieldEmpty(datos.nombre) ? 'form-control-no-respondido' : 'form-control'}`} />
                </div>
                <div className='form-group'>
                  <label htmlFor="rut" className="label-negrita">Rut</label>
                  <label className='label-gris'>Ej: 23245245-K</label>
                  <input
                    type="text"
                    id="rut"
                    name="rut"
                    value={datos.rut || ''}
                    onChange={handleInputChange}
                    className={`form-control ${isFieldEmpty(datos.rut) ? 'form-control-no-respondido' : 'form-control'}`} />
                  {!rutValido && (
                    <label className='label-roja'>RUT inválido</label>
                  )}
                </div>
                  <div className='form-group'>
                    <label htmlFor="Intereses" className="label-negrita">Intereses </label>
                    <label className='label-gris'>Señala tus principales intereses personales o profesionales.</label>
                    <label className='label-gris'>Máximo 3 palabras separadas por coma.</label>
                    <input
                      type="text"
                      id="Intereses"
                      name="intereses"
                      value={datos.intereses || ''}
                      onChange={(event) => handleInputChange(event)}
                      className={`form-control ${isFieldEmpty(datos.intereses) ? 'form-control-no-respondido' : 'form-control'}`} />
                  </div>
                  </div>
                  <div className='columna'>
                  <div className='form-group'>
                    <label htmlFor="apellido" className="label-negrita">Apellido:</label>
                    <label className='label-gris'>Ej: Lagos</label>
                    <input
                      type="text"
                      id="apellido"
                      name="apellido"
                      value={datos.apellido || ''}
                      onChange={handleInputChange}
                      className={`form-control ${isFieldEmpty(datos.apellido) ? 'form-control-no-respondido' : 'form-control'}`} />
                  </div>
                  <div className='form-group'>
                    <label className='label-negrita'>País de domicilio</label>
                    <select
                      value={datosForm.id_pais_domicilio || ''}
                      className={`form-control ${isFieldEmpty(datosForm.id_pais_domicilio) ? 'form-control-no-respondido' : 'form-control'}`}
                      name='id_pais_domicilio'
                      onChange={(event) => {
                        setMostrarRegion(event.target.value === '1');
                        handleDropdownChange(event);
                      }}>
                      <option value=''>Escoga una opción</option>
                      {datosPaises
                        .slice() 
                        .sort((a, b) => a.pais.localeCompare(b.pais)) 
                        .map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.pais}
                          </option>
                        ))}
                    </select>
                  </div>
                 
                  <div className='form-group'>
                    <div className='nueva-clase'>
                      <label htmlFor="celular" className="etiqueta-celular">Celular:</label>
                      <label className='etiqueta-ejemplo'>Ej: 962806381</label>
                    </div>
                    
                    <div className='telephony-fields'>
                      <input
                          type="text"
                          id="codigo-telefonico"
                          name="codigo-telefonico"
                          value={codigoTelefonico}
                          readOnly
                          className="form-control codigo-input"
                        />
                      <input
                        type="tel"
                        id="celular"
                        name="celular"
                        value={datos.celular || ''}
                        onChange={handleInputChange}
                        disabled={!datosForm.id_pais_domicilio}
                        className={`form-control ${isFieldEmpty(datos.celular) ? 'form-control-no-respondido' : 'form-control'}`} 
                        />
                    </div>
                    {!celValido && (
                      <label className='label-roja'>Celular inválido</label>
                    )}
                  </div>
                  {mostrarRegion && (datosForm.regionActualDomicilio == null ) && (
                    <div className='form-group'>
                      <label className='label-negrita'>Región</label>
                      <select
                        className={`form-control ${isFieldEmpty(datosForm.regionActualDomicilio) ? 'form-control-no-respondido' : 'form-control'}`}
                        value={datosForm.regionActualDomicilio || ''}
                        name='region_domicilio'
                        onChange={(event) => handleDropdownChangeRegion(event)}>
                        <option value=''>Selecciona tu región:</option>
                        {datosCompromiso
                          .slice()
                          .sort((a, b) => a.nombre.localeCompare(b.nombre))
                          .map((region) => (
                            <option key={region.id} value={region.id}>
                              {region.nombre}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                  {mostrarRegion && (datosForm.regionActualDomicilio !== null || nuevosDatos.region_domicilio) && (
                    <div className='form-group'>
                      <label className='label-negrita'>Región</label>
                      <select
                        className={`form-control ${isFieldEmpty(datosForm.regionActualDomicilio.id) ? 'form-control-no-respondido' : 'form-control'}`}
                        value={datosForm.regionActualDomicilio.id || ''}
                        name='region_domicilio'
                        onChange={(event) => handleDropdownChangeRegion(event)}>
                        <option value=''>Selecciona tu región:</option>
                        {datosCompromiso
                          .slice()
                          .sort((a, b) => a.nombre.localeCompare(b.nombre))
                          .map((region) => (
                            <option key={region.id} value={region.id}>
                              {region.nombre}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                  </div>
                  </div>
                  </>
            )}
            {currentStep===2 &&(
              <><div className='contenedor-fila'>
              <label className={`label-fila texto-subrayado`}>Estudios</label>
              <label className='label-fila'>Laboral</label>
              <label className='label-fila'>Experiencia</label>
              <div className='columna-responsive'>
                <div className='form-group'>
                  <label className='label-negrita'>Profesión 1</label>
                  <label className='label-gris'>Elige la opción que más se acomode a tu profesión</label>
                  <select
                    className={`form-control ${isFieldEmpty(datosForm.profesion1) ? 'form-control-no-respondido' : 'form-control'}`}
                    name='profesion1'
                    value={datosForm.profesion1}
                    onChange={(event) => handleArray(event)}>
                    <option value="">Escoge una opción</option>
                    {datosProfesiones
                      .slice() 
                      .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                      .map((profesion) => (
                        <option key={profesion.id} value={profesion.id}>
                          {profesion.nombre}
                        </option>
                      ))}
                  </select>
                </div>
                <div className='form-group'>
                  <label className='label-negrita'>Profesión 2</label>
                  <label className='label-gris'>Selecciona otra profesión si es que tienes</label>
                  <select
                    className={`form-control ${isFieldEmpty(datosForm.profesion2) ? 'form-control-no-respondido' : 'form-control'}`}
                    name='profesion2'
                    value={datosForm.profesion2 || ''}
                    onChange={(event) => handleArray(event)}>
                    <option value="">Escoge una opción</option>
                    {datosProfesiones
                      .slice() 
                      .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                      .map((profesion) => (
                        <option key={profesion.id} value={profesion.id}>
                          {profesion.nombre}
                        </option>
                      ))}
                  </select>
                </div>
                <div className='form-group'>
                  <label className='label-negrita'>Universidad</label>
                  <label className='label-gris-universidad'>Selecciona tu universidad de pregrado. Si es que no está en la lista selecciona "Otra" e indica la carrera.</label>
                  <select
                    value={datosForm.universidad || ''}
                    className={`form-control-u ${isFieldEmpty(datosForm.universidad) ? 'form-control-no-respondido' : 'form-control-u'}`}
                    name='universidad'
                    onChange={handleDropdownChange}>
                    <option value="">Selecciona una universidad:</option>
                    {datosUniversidades
                      .slice() 
                      .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                      .map((universidad) => (
                        <option key={universidad.id} value={universidad.nombre}>
                          {universidad.nombre}
                        </option>
                      ))}
                  </select>
                </div>
                <div className='form-group'>
                  <label htmlFor="postgradoElection" className="label-negrita">¿Tienes postgrado?</label>
                  <label className='label-gris-postgrado'>Se considera como tal al que acredita la obtención de un grado académico equivalente a magíster o doctorado, otorgado por una universidad reconocida por el Estado, nacional o extranjera.</label>
                  <select
                    id="postgradoElection"
                    name="postgrado"
                    onChange={handleAdicional}
                    value={selectedPostgrado}
                    className={`form-control-p ${isFieldEmpty(selectedPostgrado) ? 'form-control-no-respondido' : 'form-control-p'}`}>
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                  </select>
                </div>
                {mostrarCuadroTextoPostgrado && (
                  <div className='form-group'>
                    <label htmlFor="postgrado" className="label-negrita">¿Qué postgrado tienes?</label>
                    <label className='label-gris'>Indicar título y universidad</label>
                    <input
                      type="text"
                      id="postgrado"
                      name="postgrado"
                      value={datosForm.postgrado}
                      onChange={handleInputChange}
                      className={`form-control ${isFieldEmpty(datosForm.postgrado) ? 'form-control-no-respondido' : 'form-control'}`}
                    />
                  </div>
                )}
              </div>

              </div><div className='columnas'><div className='columna-izquierda'>
              <div className='form-group'>
                <label className='label-negrita'>Profesión</label>
                <label className='label-gris'>Elige la opción que más se acomode a tu profesión</label>
                <select
                  className={`form-control ${isFieldEmpty(datosForm.profesion1) ? 'form-control-no-respondido' : 'form-control'}`}
                  name='profesion1'
                  value={datosForm.profesion1}
                  onChange={(event) => handleArray(event)}>
                  <option value="">Escoge una opción</option>
                  {datosProfesiones
                    .slice() 
                    .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                    .map((profesion) => (
                      <option key={profesion.id} value={profesion.id}>
                        {profesion.nombre}
                      </option>
                    ))}
                </select>
              </div>
              <div className='form-group'>
                  <label className='label-negrita'>Universidad</label>
                  <label className='label-gris-universidad'>Selecciona tu universidad de pregrado. Si es que no está en la lista selecciona "Otra" e indica la carrera.</label>
                  <select
                    value={datosForm.universidad || ''}
                    className={`form-control ${isFieldEmpty(datosForm.universidad) ? 'form-control-no-respondido' : 'form-control'}`}
                    name='universidad'
                    onChange={handleDropdownChange}>
                    <option value="">Selecciona una universidad:</option>
                    {datosUniversidades
                      .slice() 
                      .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                      .map((universidad) => (
                        <option key={universidad.id} value={universidad.nombre}>
                          {universidad.nombre}
                        </option>
                      ))}
                  </select>
                </div><div className='form-group'>
                  <label htmlFor="postgradoElection" className="label-negrita">Postgrado</label>
                  <label className='label-gris-postgrado'>Se considera como tal al que acredita la obtención de un grado académico equivalente a magíster o doctorado, otorgado por una universidad reconocida por el Estado, nacional o extranjera.</label>
                  <select
                    id="postgradoElection"
                    name="postgrado"
                    onChange={handleAdicional}
                    value={selectedPostgrado}
                    className={`form-control ${isFieldEmpty(selectedPostgrado) ? 'form-control-no-respondido' : 'form-control'}`}>
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                  </select>
                </div>
                </div>
                <div className='columna'>
                <div className='form-group'>
                <label className='label-negrita'>Profesión 2</label>
                <label className='label-gris'>Selecciona otra profesión si es que tienes</label>
                <select
                  className={`form-control ${isFieldEmpty(datosForm.profesion2) ? 'form-control-no-respondido' : 'form-control'}`}
                  name='profesion2'
                  value={datosForm.profesion2 || ''}
                  onChange={(event) => handleArray(event)}>
                  <option value="">Escoge una opción</option>
                  {datosProfesiones
                    .slice() 
                    .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                    .map((profesion) => (
                      <option key={profesion.id} value={profesion.id}>
                        {profesion.nombre}
                      </option>
                    ))}
                </select>
              </div>
            {mostrarCuadroTextoPostgrado && (
              <div className='form-group'>
                <label htmlFor="postgrado" className="label-negrita">Qué postgrado tienes</label>
                <label className='label-gris'>Indicar título y universidad</label>
                <input
                  type="text"
                  id="postgrado"
                  name="postgrado"
                  value={datosForm.postgrado}
                  onChange={handleInputChange}
                  className={`form-control ${isFieldEmpty(datosForm.postgrado) ? 'form-control-no-respondido' : 'form-control'}`}
                   />
              </div>
            )}
            </div>
            </div>
            </>)}
            {currentStep ===3 && (
              <><div className='contenedor-fila'>
              <label className='label-fila'>Estudios</label>
              <label className={`label-fila texto-subrayado`}>Laboral</label>
              <label className='label-fila'>Experiencia</label>
              </div>
              <div className='columna-responsive'>
                <div className='form-group'>
                  <label className='label-negrita'>Cargo actual</label>
                  <label className='label-gris2'>Elige la opción que más se acomode a tu cargo. Si no tienes cargo selecciona "Ninguno"</label>
                  <select
                    value={datosForm.id_cargo || ''}
                    className={`form-control ${isFieldEmpty(datosForm.id_cargo) ? 'form-control-no-respondido' : 'form-control'}`}
                    name="id_cargo"
                    onChange={(event) => handleDropdownChange(event)}>
                    <option value="">Selecciona un cargo:</option>
                    {datosCargos
                      .slice()
                      .sort((a, b) => a.cargo.localeCompare(b.cargo)) // Ordenar alfabéticamente
                      .map((cargo) => (
                        <option key={cargo.id} value={cargo.id}>
                          {cargo.cargo}
                        </option>
                      ))}
                  </select>
                </div>
                <div className='form-group'>
                  <label htmlFor="empresa" className="label-negrita">Empresa</label>
                  <label className='label-gris'>Nombre de la empresa en la que trabajas</label>
                  <input
                    type="text"
                    id="empresa"
                    name="empresa_actual"
                    value={datos.empresa_actual || ''}
                    onChange={handleInputChange}
                    className={`form-control ${isFieldEmpty(datosForm.empresa_actual) ? 'form-control-no-respondido' : 'form-control'}`} />
                </div>
                <div>
                  {datosIndustrias ? (
                    <div className='form-group'>
                      <label className='label-negrita'>Industria Actual</label>
                      <label className='label-gris2'>Se entiende como industria el área de la economía en la que tu empresa u organización se desempeña</label>
                      <select
                        value={datosForm.id_industria_actual || ''}
                        className={`form-control ${isFieldEmpty(datosForm.id_industria_actual) ? 'form-control-no-respondido' : 'form-control'}`}
                        name='id_industria_actual'
                        onChange={(event) => handleDropdownChange(event)}>
                        <option value="">Escoge una opción</option>
                        {datosIndustrias
                          .slice() 
                          .sort((a, b) => a.nombre_industria.localeCompare(b.nombre_industria)) // Ordenar alfabéticamente
                          .map((industria) => (
                            <option key={industria.id} value={industria.id}>
                              {industria.nombre_industria}
                            </option>
                          ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor="rolAdicional" className="label-negrita2">¿Desempeñas algún otro rol, cargo o funcion actualmente?</label>
                  <select
                    id="rolAdicional"
                    name="rolAdicional"
                    className="form-control" 
                    onChange={(event) => handleAdicional(event)}>
                    <option value="">Escoga una opción</option>
                    <option value="">No</option>
                    <option value="1">Si</option>
                  </select>
                </div>
                {mostrarCargoAdicional && (
                  <><label></label>
                  <div className='form-group'>
                    <label className='label-negrita'>Cargo adicional o complementario</label>
                    <select
                      className={`form-control ${isFieldEmpty(datosForm.id_cargo_adicional) ? 'form-control-no-respondido' : 'form-control'}`}
                      name="id_cargo_adicional"
                      value={datosForm.id_cargo_adicional}
                      onChange={(event) => handleDropdownChange(event)}>
                      <option value="">Escoge una opción</option>
                      {datosCargos
                        .slice() 
                        .sort((a, b) => a.cargo.localeCompare(b.cargo)) // Ordenar alfabéticamente
                        .map((cargo) => (
                          <option key={cargo.id} value={cargo.id}>
                            {cargo.cargo}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='form-group'>
                        <label htmlFor="empresa" className="label-negrita">Empresa adicional:</label>
                        <input
                          type="text"
                          id="empresa"
                          name="empresa_adicional"
                          value={datos.empresa_adicional || ''}
                          onChange={handleInputChange}
                          className={`form-control ${isFieldEmpty(datosForm.empresa_adicional) ? 'form-control-no-respondido' : 'form-control'}`} />
                      </div>       
                  <div className='form-group'>
                        <label className='label-negrita'>Industria adicional o complementaria</label>
                        <select
                          className={`form-control-industriaAdicional ${isFieldEmpty(datosForm.id_industria_adicional) ? 'form-control-no-respondido' : 'form-control-industriaAdicional'}`}
                          name='id_industria_adicional'
                          value={datosForm.id_industria_adicional}
                          onChange={(event) => handleDropdownChange(event)}>
                          <option value="">Escoge una opción</option>
                          {datosIndustrias
                            .slice() 
                            .sort((a, b) => a.nombre_industria.localeCompare(b.nombre_industria)) // Ordenar alfabéticamente
                            .map((industria) => (
                              <option key={industria.id} value={industria.id}>
                                {industria.nombre_industria}
                              </option>
                            ))}
                        </select>
                      </div></>
                )}
              </div>
                              
              <div className='columnas'><div className='columna-izquierda'><div>
                {datosCargos ? (
                  <div className='form-group'>
                    <label className='label-negrita'>Cargo actual</label>
                    <label className='label-gris2'>Elige la opción que más se acomode a tu cargo. Si no tienes cargo selecciona "Ninguno"</label>
                    <select
                      value={datosForm.id_cargo || ''}
                      className={`form-control ${isFieldEmpty(datosForm.id_cargo) ? 'form-control-no-respondido' : 'form-control'}`}
                      name="id_cargo"
                      onChange={(event) => handleDropdownChange(event)}>
                      <option value="">Selecciona un cargo:</option>
                      {datosCargos
                        .slice() 
                        .sort((a, b) => a.cargo.localeCompare(b.cargo)) // Ordenar alfabéticamente
                        .map((cargo) => (
                          <option key={cargo.id} value={cargo.id}>
                            {cargo.cargo}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : null}
              </div><div>
                  {datosIndustrias ? (
                    <div className='form-group'>
                      <label className='label-negrita'>Industria Actual</label>
                      <label className='label-gris2'>Se entiende como industria el área de la economía en la que tu empresa u organización se desempeña</label>
                      <select
                        value={datosForm.id_industria_actual || ''}
                        className={`form-control ${isFieldEmpty(datosForm.id_industria_actual) ? 'form-control-no-respondido' : 'form-control'}`}
                        name='id_industria_actual'
                        onChange={(event) => handleDropdownChange(event)}>
                        <option value="">Escoge una opción</option>
                        {datosIndustrias
                          .slice() 
                          .sort((a, b) => a.nombre_industria.localeCompare(b.nombre_industria)) // Ordenar alfabéticamente
                          .map((industria) => (
                            <option key={industria.id} value={industria.id}>
                              {industria.nombre_industria}
                            </option>
                          ))}
                      </select>
                    </div>
                  ) : null}
                </div>
                {mostrarCargoAdicional && (
                  <><label></label>
                  <div className='form-group'>
                    <label className='label-negrita'>Cargo adicional o complementario</label>
                    <select
                      className={`form-control ${isFieldEmpty(datosForm.id_cargo_adicional) ? 'form-control-no-respondido' : 'form-control'}`}
                      name="id_cargo_adicional"
                      value={datosForm.id_cargo_adicional}
                      onChange={(event) => handleDropdownChange(event)}>
                      <option value="">Escoge una opción</option>
                      {datosCargos
                        .slice() 
                        .sort((a, b) => a.cargo.localeCompare(b.cargo)) // Ordenar alfabéticamente
                        .map((cargo) => (
                          <option key={cargo.id} value={cargo.id}>
                            {cargo.cargo}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='form-group'>
                        <label className='label-negrita'>Industria adicional o complementaria</label>
                        <select
                          className={`form-control-industriaAdicional ${isFieldEmpty(datosForm.id_industria_adicional) ? 'form-control-no-respondido' : 'form-control-industriaAdicional'}`}
                          name='id_industria_adicional'
                          value={datosForm.id_industria_adicional}
                          onChange={(event) => handleDropdownChange(event)}>
                          <option value="">Escoge una opción</option>
                          {datosIndustrias
                            .slice() 
                            .sort((a, b) => a.nombre_industria.localeCompare(b.nombre_industria)) // Ordenar alfabéticamente
                            .map((industria) => (
                              <option key={industria.id} value={industria.id}>
                                {industria.nombre_industria}
                              </option>
                            ))}
                        </select>
                      </div></>
                )}
                </div>
                <div className='columna'>
                <div className='form-group'>
                  <label htmlFor="empresa" className="label-negrita">Empresa</label>
                  <label className='label-gris'>Nombre de la empresa en la que trabajas</label>
                  <input
                    type="text"
                    id="empresa"
                    name="empresa_actual"
                    value={datos.empresa_actual || ''}
                    onChange={handleInputChange}
                    className={`form-control ${isFieldEmpty(datosForm.empresa_actual) ? 'form-control-no-respondido' : 'form-control'}`} />
                </div>
                <div className='form-group'>
                  <label htmlFor="rolAdicional" className="label-negrita2">¿Desempeñas algún otro rol, cargo o funcion actualmente?</label>
                  <select
                    id="rolAdicional"
                    name="rolAdicional"
                    className="form-control" 
                    onChange={(event) => handleAdicional(event)}>
                    <option value="">Escoga una opción</option>
                    <option value="">No</option>
                    <option value="1">Si</option>
                  </select>
                </div>
              {mostrarCargoAdicional && (
                <><div className='form-group'>
                        <label htmlFor="empresa" className="label-negrita">Empresa adicional:</label>
                        <input
                          type="text"
                          id="empresa"
                          name="empresa_adicional"
                          value={datos.empresa_adicional || ''}
                          onChange={handleInputChange}
                          className={`form-control ${isFieldEmpty(datosForm.empresa_adicional) ? 'form-control-no-respondido' : 'form-control'}`} />
                      </div></>        
              )}</div></div></>
            )}
            {currentStep === 4 &&(
              <><div className='contenedor-fila'>
              <label className='label-fila'>Estudios</label>
              <label className='label-fila'>Laboral</label>
              <label className={`label-fila texto-subrayado`}>Experiencia</label>
              </div>
              <div className='columna-responsive'>
                  <div className='form-group'>
                    <label className='label-negrita'>Años de experiencia</label>
                    <select
                      className={`form-control ${isFieldEmpty(datosForm.id_anios_experiencia) ? 'form-control-no-respondido' : 'form-control'}`}
                      name='id_anios_experiencia'
                      value={datosForm.id_anios_experiencia}
                      onChange={(event) => handleDropdownChange(event)}>
                      <option value="">Escoge una opción</option>
                      {datosAnios
                        .slice() 
                        .sort((a, b) => {
                          const orden = [1, 6, 7, 2, 3, 4, 5];
                          return orden.indexOf(a.id) - orden.indexOf(b.id);
                        })
                        .map((rangos) => (
                          <option key={rangos.id} value={rangos.id}>
                            {rangos.rango_experiencia_hasta === 500
                              ? "+25"
                              : `${rangos.rango_experiencia_desde} - ${rangos.rango_experiencia_hasta}`}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='form-group'>
                    <label htmlFor="experienciaDireccion" className="label-negrita">Experiencia en directorios</label>
                    <label className='label-gris'>Esto significa ejercer o haber ejercido el rol de directora</label>
                    <select
                      id="experienciaDireccion"
                      name="experienciaDirectorios"
                      value={datosForm.experienciaDirectorios}
                      onChange={(event) => handleDropdownChange(event)}
                      className={`form-control-e ${isFieldEmpty(datosForm.experienciaDirectorios) ? 'form-control-no-respondido' : 'form-control-e'}`}>
                      <option value=''>Escoge una opción</option>
                      <option value={true}>Si</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label htmlFor="formacionDireccion" className="label-negrita">Formación en alta dirección</label>
                    <select
                      id="formacionDireccion"
                      name="altaDireccion"
                      value={datosForm.altaDireccion}
                      onChange={(event) => handleDropdownChange(event)}
                      className={`form-control ${isFieldEmpty(datosForm.altaDireccion) ? 'form-control-no-respondido' : 'form-control'}`}>
                      <option value=''>Escoge una opción</option>
                      <option value={true}>Si</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label className='label-negrita'>Áreas de experiencia</label>
                    <label className='label-roja'>Seleccionar máximo 5 respuestas</label>
                    <label className='label-gris2'>Indica las áreas en las que has trabajado anteriormente</label>
                    <div className='form-group'>
                      <Select 
                        sx={{ '& option': { fontSize: '16px' } }}
                        className={`form-control max-width ${isFieldEmpty(datosForm.areas) ? 'form-control-no-respondido max-width' : 'form-control max-width'}`}
                        multiple
                        value={datosForm.areas}
                        onChange={handleSeleccion}
                        MenuProps={menuProps}
                        inputProps={{
                          name: 'areas',
                          id: 'opciones-multiple',
                        }}
                      >
                        <MenuItem value="" disabled>Selecciona una opción</MenuItem>
                        {datosAreas
                          .slice()
                          .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                          .map(opcion => (
                            <MenuItem key={opcion.id} value={opcion.id}>{opcion.nombre}</MenuItem>
                          ))}
                      </Select>
                      <label className='label-negrita-experiencia'>Areas seleccionadas: {stringAreas}</label>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='label-negrita'>Sector o industria en la que tienes experiencia</label>
                    <label className='label-roja2'>Seleccionar máximo 5 respuestas</label>
                    <Select 
                      sx={{ '& option': { fontSize: '16px' } }}
                      className={`form-control ${isFieldEmpty(datosForm.industriasExperiencia) ? 'form-control-no-respondido' : 'form-control'}`}
                      multiple
                      value={datosForm.industriasExperiencia}
                      onChange={handleSeleccion}
                      MenuProps={menuProps}
                      inputProps={{
                        name: 'industriasExperiencia',
                        id: 'opciones-multiple',
                      }}
                    >
                      <MenuItem value="" disabled>Selecciona una opción</MenuItem>
                      {datosIndustrias
                        .slice() 
                        .sort((a, b) => a.nombre_industria.localeCompare(b.nombre_industria)) // Ordenar alfabéticamente
                        .map(opcion => (
                          <MenuItem key={opcion.id} value={opcion.id}>{opcion.nombre_industria}</MenuItem>
                        ))}
                    </Select>
                    <label className='label-negrita-experiencia'>Industrias seleccionadas: {stringIndustrias}</label>
                  </div>
                    <div className='form-group'>
                      <label className='label-negrita-competencias'>Competencias</label>
                      <label className='label-roja-competencias'> Seleccionar máximo 5 respuestas </label>
                      <div></div>
                      <label className='label-gris-competencias'> priorizando las fortalezas y talentos que más te representan</label>
                      <Select 
                        sx={{ '& option': { fontSize: '16px' } }}
                        className={`form-control-competencias ${isFieldEmpty(datosForm.competencias) ? 'form-control-no-respondido' : 'form-control-competencias'}`}
                        multiple
                        value={datosForm.competencias}
                        onChange={handleSeleccion}
                        MenuProps={menuProps}
                        inputProps={{
                          name: 'competencias',
                          id: 'opciones-multiple',
                        }}
                      >
                        <MenuItem value="" disabled>Selecciona una opción</MenuItem>
                        {datosCompetencia
                          .slice()
                          .sort((a, b) => a.competencia.localeCompare(b.competencia)) // Ordenar alfabéticamente
                          .map(opcion => (
                            <MenuItem key={opcion.id} value={opcion.id}>{opcion.competencia}</MenuItem>
                          ))}
                      </Select>
                      <label className='label-negrita-experiencia'>Competencias seleccionadas: {stringCompetencias}</label>
                    </div>
                </div>


              <div className='columnas'>
                <div className='columna-izquierda'>
                <div className='form-group'>
                <label className='label-negrita'>Años de experiencia</label>
                <select
                  className={`form-control ${isFieldEmpty(datosForm.id_anios_experiencia) ? 'form-control-no-respondido' : 'form-control'}`}
                  name='id_anios_experiencia'
                  value={datosForm.id_anios_experiencia}
                  onChange={(event) => handleDropdownChange(event)}>
                  <option value="">Escoge una opción</option>
                  {datosAnios
                    .slice() 
                    .sort((a, b) => {
                      const orden = [1, 6, 7, 2, 3, 4, 5];
                      return orden.indexOf(a.id) - orden.indexOf(b.id);
                    })
                    .map((rangos) => (
                      <option key={rangos.id} value={rangos.id}>
                        {rangos.rango_experiencia_hasta === 500
                          ? "+25"
                          : `${rangos.rango_experiencia_desde} - ${rangos.rango_experiencia_hasta}`}
                      </option>
                    ))}
                </select>
              </div><div className='form-group'>
                  <label htmlFor="experienciaDireccion" className="label-negrita">Experiencia en directorios</label>
                  <label className='label-gris'>Esto significa ejercer o haber ejercido el rol de directora</label>
                  <select
                    id="experienciaDireccion"
                    name="experienciaDirectorios"
                    value={datosForm.experienciaDirectorios}
                    onChange={(event) => handleDropdownChange(event)}
                    className={`form-control ${isFieldEmpty(datosForm.experienciaDirectorios) ? 'form-control-no-respondido' : 'form-control'}`}>
                    <option value=''>Escoge una opción</option>
                    <option value={true}>Si</option>
                    <option value={false}>No</option>
                  </select>
                </div><div className='form-group'>
                  <label htmlFor="formacionDireccion" className="label-negrita">Formacion en alta dirección</label>
                  <select
                    id="formacionDireccion"
                    name="altaDireccion"
                    value={datosForm.altaDireccion}
                    onChange={(event) => handleDropdownChange(event)}
                    className={`form-control ${isFieldEmpty(datosForm.altaDireccion) ? 'form-control-no-respondido' : 'form-control'}`}>
                    <option value=''>Escoge una opción</option>
                    <option value={true}>Si</option>
                    <option value={false}>No</option>
                  </select>
                </div><div className='form-group'>
                  <label className='label-negrita'>Áreas de experiencia</label>
                  <label className='label-roja'>Seleccionar máximo 5 respuestas</label>
                  <label className='label-gris2'>Indica las áreas en las que has trabajado anteriomente</label>
                  <div className='form-group'>
                  <Select 
                    sx={{ '& option': { fontSize: '16px' } }}
                    className={`form-control max-width ${isFieldEmpty(datosForm.areas) ? 'form-control-no-respondido max-width' : 'form-control max-width'}`}
                    multiple
                    value={datosForm.areas}
                    onChange={handleSeleccion}
                    MenuProps={menuProps}
                    inputProps={{
                      name: 'areas',
                      id: 'opciones-multiple',
                    }}
                  >
                    <MenuItem value="" disabled>Selecciona una opción</MenuItem>
                    {datosAreas
                      .slice()
                      .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                      .map(opcion => (
                        <MenuItem key={opcion.id} value={opcion.id}>{opcion.nombre}</MenuItem>
                      ))}
                  </Select>
                    <label className='label-negrita-experiencia'>Areas seleccionadas: {stringAreas}</label>
                  </div>
                </div>
                </div>
                <div className='columna'>
                <div className='form-group'>
                  <label className='label-negrita'>Sector o industria en la que tienes experiencia</label>
                  <label className='label-roja'>Seleccionar máximo 5 respuestas</label>
                  <Select 
                      sx={{ '& option': { fontSize: '16px' } }}
                      className={`form-control ${isFieldEmpty(datosForm.industriasExperiencia) ? 'form-control-no-respondido' : 'form-control'}`}
                      multiple
                      value={datosForm.industriasExperiencia}
                      onChange={handleSeleccion}
                      MenuProps={menuProps}
                      inputProps={{
                        name: 'industriasExperiencia',
                        id: 'opciones-multiple',
                      }}
                    >
                      <MenuItem value="" disabled>Selecciona una opción</MenuItem>
                      {datosIndustrias
                        .slice() 
                        .sort((a, b) => a.nombre_industria.localeCompare(b.nombre_industria)) // Ordenar alfabéticamente
                        .map(opcion => (
                          <MenuItem key={opcion.id} value={opcion.id}>{opcion.nombre_industria}</MenuItem>
                        ))}
                    </Select>
                      <label className='label-negrita-experiencia'>Industrias seleccionadas: {stringIndustrias}</label>
                </div><div className='form-group'>
                  <label className='label-negrita'>Competencias</label>
                  <label><span className='label-roja'>Seleccionar máximo 5 respuestas </span>
                  <span className='label-gris'>, priorizando las fortalezas y talentos que mas te representan</span></label>
                  <Select 
                    sx={{ '& option': { fontSize: '16px' } }}
                    className={`form-control ${isFieldEmpty(datosForm.competencias) ? 'form-control-no-respondido' : 'form-control'}`}
                    multiple
                    value={datosForm.competencias}
                    onChange={handleSeleccion}
                    MenuProps={menuProps}
                    inputProps={{
                      name: 'competencias',
                      id: 'opciones-multiple',
                    }}
                  >
                    <MenuItem value="" disabled>Selecciona una opción</MenuItem>
                    {datosCompetencia
                      .slice()
                      .sort((a, b) => a.competencia.localeCompare(b.competencia)) // Ordenar alfabéticamente
                      .map(opcion => (
                        <MenuItem key={opcion.id} value={opcion.id}>{opcion.competencia}</MenuItem>
                      ))}
                  </Select>
                      <label className='label-negrita-experiencia'>Competencias seleccionadas: {stringCompetencias}</label>
                </div>
                </div>
                </div></>
            )}
            {currentStep===5 && (
              <>
              <div className={`columna-responsive `}>
                  <div className='form-group'>
                    <label htmlFor="cambioRegion" className="label-negrita">Posibilidad de cambiarse de región</label>
                    <select
                      id="cambioRegion"
                      value={datosForm.id_posibilidad_cambiarse_region || ''}
                      name="id_posibilidad_cambiarse_region"
                      onChange={handleDropdownChange}
                      className={`form-control ${isFieldEmpty(datosForm.id_posibilidad_cambiarse_region) ? 'form-control-no-respondido' : 'form-control'}`}>
                      <option value=''>Escoge una opción</option>
                      <option value={1}>Si</option>
                      <option value={2}>No</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label className='label-negrita'>Modalidad de preferencia</label>
                    <select
                      className={`form-control ${isFieldEmpty(datosForm.id_modalidad) ? 'form-control-no-respondido' : 'form-control'}`}
                      name='id_modalidad'
                      value={datosForm.id_modalidad || ''}
                      onChange={(event) => handleDropdownChange(event)}>
                      <option value="">Escoge una opción:</option>
                      {datosModalidades
                        .slice() 
                        .sort((a, b) => a.tipoModalidad.localeCompare(b.tipoModalidad)) // Ordenar alfabéticamente
                        .map(modalidad => (
                          <option key={modalidad.id} value={modalidad.id}>
                            {modalidad.tipoModalidad}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='form-group'>
                    <label className='label-negrita'>Disponibilidad de jornada</label>
                    <select
                      className={`form-control ${isFieldEmpty(datosForm.id_jornada) ? 'form-control-no-respondido' : 'form-control'}`}
                      name='id_jornada'
                      value={datosForm.id_jornada}
                      onChange={(event) => handleDropdownChange(event)}>
                      <option value="">Escoge una opción</option>
                      {datosJornada
                        .slice() 
                        .sort((a, b) => a.tipoJornada.localeCompare(b.tipoJornada)) // Ordenar alfabéticamente
                        .map(jornada => (
                          <option key={jornada.id} value={jornada.id}>
                            {jornada.tipoJornada}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className='form-group'>
                    <label htmlFor="disposicionViajar" className="label-negrita">Disposición a viajar</label>
                    <select
                      id="disposicionViajar"
                      name="disposicion_viajar"
                      value={datosForm.disposicion_viajar}
                      onChange={handleDropdownChange}
                      className={`form-control ${isFieldEmpty(datosForm.disposicion_viajar) ? 'form-control-no-respondido' : 'form-control'}`}>
                      <option value=''>Escoge una opción</option>
                      <option value={true}>Si</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                  <div className='form-group'>
                    <label htmlFor="expectatviaRenta" className="label-negrita">Expectativa de renta</label>
                    <input
                      type="text"
                      id="expectatviaRenta"
                      name="expectatviaRenta"
                      value={datos.expectatviaRenta || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      className={`form-control ${isFieldEmpty(datos.expectatviaRenta) ? 'form-control-no-respondido' : 'form-control'}`} />
                  </div>
                  <div className='form-group'>
                    <label className='label-negrita'>Disponibilidad</label>
                    <label className='label-roja'>Seleccionar una o más respuestas </label>
                    <label className='label-gris'>Indica las modalidades en que estarías dispuesta asumir un nuevo trabajo, proyecto o asesoría</label>
                    <Select 
                      sx={{ '& option': { fontSize: '16px' } }}
                      className={`form-control-disp ${isFieldEmpty(datosForm.disponibilidad) ? 'form-control-no-respondido' : 'form-control-disp'}`}
                      multiple
                      value={datosForm.disponibilidad}
                      onChange={handleSeleccion}
                      MenuProps={menuProps}
                      inputProps={{
                        name: 'disponibilidad',
                        id: 'opciones-multiple',
                      }}
                    >
                      <MenuItem value="" disabled>Selecciona una opción</MenuItem>
                      {datosDisponibilidad
                        .slice() 
                        .sort((a, b) => a.disponibilidad.localeCompare(b.disponibilidad)) // Ordenar alfabéticamente
                        .map(opcion => (
                          <MenuItem key={opcion.id} value={opcion.id}>{opcion.disponibilidad}</MenuItem>
                        ))}
                    </Select>
                    <label className='label-negrita-experiencia'>Disponibilidad seleccionada: {stringDisponibilidad}</label>
                  </div>
                </div>

              <div className={`columnas margin-columnas-top `}>
                <div className='columna-izquierda'>
              <div className='form-group'>
                <label className='label-negrita'>Disponibilidad</label>
                <label><span className='label-roja'>Seleccionar una o más respuestas </span>
                <span className='label-gris'>Indica las modalidades en que estarías dispuesta asumir un nuevo trabajo, proyecto o asesoría</span></label>
                <Select 
                  className={`form-control ${isFieldEmpty(datosForm.disponibilidad) ? 'form-control-no-respondido' : 'form-control'}`}
                  multiple
                  value={datosForm.disponibilidad}
                  onChange={handleSeleccion}
                  MenuProps={menuProps}
                  inputProps={{
                    name: 'disponibilidad',
                    id: 'opciones-multiple',
                  }}
                >
                  <MenuItem value="" disabled>Selecciona una opción</MenuItem>
                  {datosDisponibilidad
                    .slice() 
                    .sort((a, b) => a.disponibilidad.localeCompare(b.disponibilidad)) // Ordenar alfabéticamente
                    .map(opcion => (
                      <MenuItem key={opcion.id} value={opcion.id}>{opcion.disponibilidad}</MenuItem>
                    ))}
                </Select>
                <label className='label-negrita-experiencia'>Disponibilidad seleccionada: {stringDisponibilidad}</label>

              </div><div className='form-group'>
                  <label className='label-negrita'>Disponibilidad de jornada</label>

                  <select
                  className={`form-control ${isFieldEmpty(datosForm.id_jornada) ? 'form-control-no-respondido' : 'form-control'}`}
                  name='id_jornada'
                  value={datosForm.id_jornada}
                  onChange={(event) => handleDropdownChange(event)}
                >
                  <option value="">Escoge una opción</option>
                  {datosJornada
                    .slice() 
                    .sort((a, b) => a.tipoJornada.localeCompare(b.tipoJornada)) // Ordenar alfabéticamente
                    .map(jornada => (
                      <option key={jornada.id} value={jornada.id}>
                        {jornada.tipoJornada}
                      </option>
                    ))}
                </select>
                </div>
                <div className='form-group'>
                    <label htmlFor="expectatviaRenta" className="label-negrita">Expectativa de renta</label>
                    <input
                      type="text"
                      id="expectatviaRenta"
                      name="expectatviaRenta"
                      value={datos.expectatviaRenta || ''}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      className={`form-control ${isFieldEmpty(datos.expectatviaRenta) ? 'form-control-no-respondido' : 'form-control'}`} />
                  </div>
                </div>
                <div className='columna'><div className='form-group'>
                  <label htmlFor="cambioRegion" className="label-negrita">Posibilidad de cambiarse de región</label>
                  <select
                    id="cambioRegion"
                    value={datosForm.id_posibilidad_cambiarse_region || ''}
                    name="id_posibilidad_cambiarse_region"
                    onChange={handleDropdownChange}
                    className={`form-control ${isFieldEmpty(datosForm.id_posibilidad_cambiarse_region) ? 'form-control-no-respondido' : 'form-control'}`}>
                    <option value=''>Escoge una opción</option>
                    <option value={1}>Si</option>
                    <option value={2}>No</option>
                  </select>
                </div><div className='form-group'>
                  <label className='label-negrita'>Modalidad de preferencia</label>

                  <select
                  className={`form-control ${isFieldEmpty(datosForm.id_modalidad) ? 'form-control-no-respondido' : 'form-control'}`}
                  name='id_modalidad'
                  value={datosForm.id_modalidad || ''}
                  onChange={(event) => handleDropdownChange(event)}
                >
                  <option value="">Escoge una opción:</option>
                  {datosModalidades
                    .slice() 
                    .sort((a, b) => a.tipoModalidad.localeCompare(b.tipoModalidad)) // Ordenar alfabéticamente
                    .map(modalidad => (
                      <option key={modalidad.id} value={modalidad.id}>
                        {modalidad.tipoModalidad}
                      </option>
                    ))}
                </select>
                </div><div className='form-group'>
                  <label htmlFor="disposicionViajar" className="label-negrita">Disposición a viajar</label>
                  <select
                    id="disposicionViajar"
                    name="disposicion_viajar"
                    value={datosForm.disposicion_viajar}
                    onChange={handleDropdownChange}
                    className={`form-control ${isFieldEmpty(datosForm.disposicion_viajar) ? 'form-control-no-respondido' : 'form-control'}`}>
                    <option value=''>Escoge una opción</option>
                    <option value={true}>Si</option>
                    <option value={false}>No</option>
                  </select>
                </div></div></div></>
            )}
            {currentStep===6 && (
              <>
                <div className={`columna-responsive`}>
                    <div className='form-group'>
                      <label htmlFor="dominioIdioma" className="label-negrita">Dominio de idioma medio-avanzado:</label>
                      <select
                        className={`form-control ${isFieldEmpty(datosForm.idiomas) ? 'form-control-no-respondido' : 'form-control'}`}
                        name='idiomas'
                        value={datosForm.idiomas || ''}
                        onChange={(event) => handleArray(event)}>
                        <option value="">Selecciona una opción:</option>
                        {datosIdiomas
                          .slice() 
                          .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                          .map(idioma => (
                            <option key={idioma.id} value={idioma.id}>
                              {idioma.nombre}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className='form-group'>
                      <label htmlFor="inclusionLaboral" className="label-negrita">Tiene algún factor de inclusión laboral</label>
                      <select
                        id="inclusionLaboral"
                        name="factor"
                        onChange={(event) => handleAdicional(event)}
                        value={selectedFactor}
                        className={`form-control ${isFieldEmpty(selectedFactor) ? 'form-control-no-respondido' : 'form-control'}`}>  
                        <option value=''>Selecciona una opción</option>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                      </select>
                    </div>
                    {mostrarFactor && (
                      <div className='form-group'>
                        <label htmlFor="inclusionLaboralText" className="label-negrita">¿Cuál es su factor de inclusión laboral?</label>
                        <input
                          type="text"
                          id="inclusionLaboralText"
                          name="factor"
                          value={datos.factor || ''}
                          onChange={handleInputChange}
                          className={`form-control ${isFieldEmpty(datos.factor) ? 'form-control-no-respondido' : 'form-control'}`} />
                      </div>
                    )}
                    <div className='form-group'>
                      <label htmlFor="compromisoRegion" className="label-negrita">Tiene algún compromiso con alguna región del país</label>
                      <select
                        id="compromisoRegion"
                        name="compromiso"
                        onChange={(event) => handleAdicional(event)}
                        className={`form-control ${isFieldEmpty(selectedCompromiso) ? 'form-control-no-respondido' : 'form-control'}`}
                        value={selectedCompromiso}>
                        <option value="No">No</option>
                        <option value="Si">Sí</option>
                      </select>
                    </div>
                    {mostrarCompromiso && (
                      <div className='form-group'>
                        <label className='label-negrita'>Selecciona la región con la que estás comprometida:</label>
                        <select
                          className={`form-control ${isFieldEmpty(datosForm.id_region_con_compromiso) ? 'form-control-no-respondido' : 'form-control'}`}
                          value={datosForm.id_region_con_compromiso}
                          name='id_region_con_compromiso'
                          onChange={(event) => handleDropdownChange(event)}>
                          <option value="">Selecciona tu región:</option>
                          {datosCompromiso
                            .slice()
                            .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                            .map(region => (
                              <option key={region.id} value={region.id}>
                                {region.nombre}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}
                    <div className='form-group'>
                      <label htmlFor="puebloIndigena" className="label-negrita">Se identifica con algún pueblo originario</label>
                      <select
                        id="puebloIndigena"
                        name="pueblo"
                        value={selectedPueblo}
                        onChange={(event) => handleAdicional(event)}
                        className={`form-control ${isFieldEmpty(selectedPueblo) ? 'form-control-no-respondido' : 'form-control'}`}>
                        <option value="No">No</option>
                        <option value="Si">Sí</option>
                      </select>
                    </div>
                    {mostrarPueblo && (
                      <div className='form-group'>
                        <label htmlFor="puebloIndigenaEleccion" className="label-negrita">¿Con qué pueblo originario se identifica?</label>
                        <input
                          type="text"
                          id="puebloIndigenaEleccion"
                          name="nombrePuebloOriginario"
                          value={datos.nombrePuebloOriginario || ''}
                          onChange={handleInputChange}
                          className={`form-control ${isFieldEmpty(datos.nombrePuebloOriginario) ? 'form-control-no-respondido' : 'form-control'}`} />
                      </div>
                    )}
                    <div className='form-group'>
                      <label htmlFor="wot" className="label-negrita">¿Cómo conoció WoT?</label>
                      <select
                        className={`form-control ${isFieldEmpty(datosForm.id_conocio_wot) ? 'form-control-no-respondido' : 'form-control'}`}
                        value={datosForm.id_conocio_wot || ''}
                        name='id_conocio_wot'
                        onChange={(event) => handleDropdownChange(event)}>
                        <option value="">Selecciona una opción:</option>
                        {datosWot
                          .slice() 
                          .sort((a, b) => a.conocio.localeCompare(b.conocio)) // Ordenar alfabéticamente
                          .map(wot => (
                            <option key={wot.id} value={wot.id}>
                              {wot.conocio}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>


              <div className={`columnas margin-columnas-top `}>
                <div className='columna-izquierda'>
              <div className='form-group'>
                <label htmlFor="dominioIdioma" className="label-negrita">Dominio de idioma medio-avanzado:</label>
                <select
                className={`form-control ${isFieldEmpty(datosForm.idiomas) ? 'form-control-no-respondido' : 'form-control'}`}
                name='idiomas'
                value={datosForm.idiomas || ''}
                onChange={(event) => handleArray(event)}
              >
                <option value="">Selecciona una opción:</option>
                {datosIdiomas
                  .slice() 
                  .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                  .map(idioma => (
                    <option key={idioma.id} value={idioma.id}>
                      {idioma.nombre}
                    </option>
                  ))}
              </select>
              </div><div className='form-group'>
                  <label htmlFor="inclusionLaboral" className="label-negrita">Tiene algún factor de inclusión laboral</label>
                  <select
                    id="inclusionLaboral"
                    name="factor"
                    onChange={(event) => handleAdicional(event)}
                    value={selectedFactor}
                    className={`form-control ${isFieldEmpty(selectedFactor) ? 'form-control-no-respondido' : 'form-control'}`}>  
                    <option value=''>Selecciones una opción</option>
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                  </select>
                </div>
            
            <div className='form-group'>
              <label htmlFor="compromisoRegion" className="label-negrita">Tiene algún compromiso con alguna región del pais</label>
              <select
                id="compromisoRegion"
                name="compromiso"
                onChange={(event) => handleAdicional(event)}
                className={`form-control ${isFieldEmpty(selectedCompromiso) ? 'form-control-no-respondido' : 'form-control'}`}
                value={selectedCompromiso}>
                
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </div>
            <div className='form-group'>
              <label htmlFor="puebloIndigena" className="label-negrita">Se identifica con algún pueblo originario</label>
              <select
                id="puebloIndigena"
                name="pueblo"
                value={selectedPueblo}
                onChange={(event) => handleAdicional(event)}
                className={`form-control ${isFieldEmpty(selectedPueblo) ? 'form-control-no-respondido' : 'form-control'}`}>
                <option value="No" >No</option>
                <option value="Si">Si</option>
              </select>
            </div>
            <div className='form-group'>
                <label htmlFor="wot" className="label-negrita">Cómo conocío WoT</label>
                <select
                className={`form-control ${isFieldEmpty(datosForm.id_conocio_wot) ? 'form-control-no-respondido' : 'form-control'}`}
                value={datosForm.id_conocio_wot || ''}
                name='id_conocio_wot'
                onChange={(event) => handleDropdownChange(event)}
              >
                <option value="">Selecciona una opción:</option>
                {datosWot
                  .slice() 
                  .sort((a, b) => a.conocio.localeCompare(b.conocio)) // Ordenar alfabéticamente
                  .map(wot => (
                    <option key={wot.id} value={wot.id}>
                      {wot.conocio}
                    </option>
                  ))}
              </select>
              </div>
            </div>
            <div className='columna'>
            {mostrarFactor&& (
              <div className='form-group'>
                <label htmlFor="inclusionLaboralText" className="label-negrita">¿Cual es su factor de inclusión laboral?</label>
                <input
                  type="text"
                  id="inclusionLaboralText"
                  name="factor"
                  value={datos.factor || ''}
                  onChange={handleInputChange}
                  className={`form-control ${isFieldEmpty(datos.factor) ? 'form-control-no-respondido' : 'form-control'}`} />
              </div>
            )}
            {mostrarCompromiso && (
              <div className='form-group'>
              <label className='label-negrita'>Selecciona la region con cual estas comprometida:</label>
              <select
                className={`form-control ${isFieldEmpty(datosForm.id_region_con_compromiso) ? 'form-control-no-respondido' : 'form-control'}`}
                value={datosForm.id_region_con_compromiso}
                name='id_region_con_compromiso'
                onChange={(event) => handleDropdownChange(event)}
              >
                <option value="">Selecciona tu región:</option>
                {datosCompromiso
                  .slice()
                  .sort((a, b) => a.nombre.localeCompare(b.nombre)) // Ordenar alfabéticamente
                  .map(region => (
                    <option key={region.id} value={region.id}>
                      {region.nombre}
                    </option>
                  ))}
              </select>
              </div>
            )}
            {mostrarPueblo&& (
              <div className='form-group'>
                <label htmlFor="puebloIndigenaEleccion" className="label-negrita">Con que pueblo originario se identifica?</label>
                <input
                  type="text"
                  id="puebloIndigenaEleccion"
                  name="nombrePuebloOriginario"
                  value={datos.nombrePuebloOriginario || ''}
                  onChange={handleInputChange}
                  className={`form-control ${isFieldEmpty(datos.nombrePuebloOriginario) ? 'form-control-no-respondido' : 'form-control'}`} />
              </div>
            )}
            </div></div></>
            )}
            {currentStep===7 && (
              <>
              <div className='columna-responsive'>
                <div className='form-group'>
                  <label htmlFor="linkedin" className="label-negrita">Perfil de LinkedIn</label>
                  <label className='label-gris'>Puede ser el perfil de RRSS que quieras compartir con nosotros.</label>
                  <input
                    type="text"
                    id="linkedin"
                    name="redesSociales"
                    value={datos.redesSociales || ''}
                    onChange={handleInputChange}
                    className={`form-control ${isFieldEmpty(datos.redesSociales) ? 'form-control-no-respondido' : 'form-control'}`} />
                </div>
                <div className='form-group'>
                  <label htmlFor="personalidad" className="label-negrita">Respuesta formulario de personalidad</label>
                  <select
                    className={`form-control ${isFieldEmpty(datosForm.id_personalidad) ? 'form-control-no-respondido' : 'form-control'}`}
                    name='id_personalidad'
                    value={datosForm.id_personalidad || ''}
                    onChange={(event) => handleDropdownChange(event)}>
                    <option value="">Selecciona una opción:</option>
                    {datosPersonalidad
                      .slice()
                      .sort((a, b) => a.personalidad.localeCompare(b.personalidad)) // Ordenar alfabéticamente
                      .map(personalidad => (
                        <option key={personalidad.id} value={personalidad.id}>
                          {personalidad.personalidad}
                        </option>
                      ))}
                  </select>
                </div>
              
                <div className='form-group'>
                  <label htmlFor="brief" className="label-negrita">Realiza un pequeño brief sobre ti</label>
                  <label className='label-gris'>Máximo 350 caracteres</label>
                  <textarea
                    id="brief"
                    name="brief"
                    value={datos.brief || ''}
                    onChange={handleInputChange}
                    placeholder='[carrera] de la Universidad [nombre de la universidad], con más de [años] de experiencia en áreas [indicar áreas de experiencia].
                    Tengo la capacidad de [indicar capacidades y habilidades] y los siguientes logros en mi experiencia laboral [indicar logros].
                    Además, me interesa desarrollar [indicar que le interesa desarrollar] y otros intereses como [indicar otros intereses].'
                    className={`form-control-brief ${isFieldEmpty(datos.brief) ? 'form-control-brief-no-respondido' : 'form-control-brief'}`} 
                    maxLength="350" />
                </div>
                <div className='form-group'>
                  <label htmlFor="brief" className="label-negrita">Sube tu CV:</label>
                  <input type='file' id='pdfFile' name='pdfFile' accept='.pdf' onChange={handleFileChange} className='cv' />
                  <button className='button-cv' onClick={handleFile}>Enviar</button>
                </div>
                <div className='form-group'>
                  <label htmlFor="brief" className="label-negrita">
                    <input
                      type="checkbox"
                      checked={datosForm.declaracion}
                      name='declaracion'
                      onChange={handleCheckboxChange}
                    />
                    Declaro que los datos ingresados son verídicos
                  </label>
                </div>
              </div>
              
              <div className='columnas'>
              <div className='columna-izquierda-7'>
              <div className='form-group'>
                <label htmlFor="linkedin" className="label-negrita">Perfil de linkedin</label>
                <label className='label-gris'>Puede ser el perfil de RRSS que quieras compartir con nosotros.</label>
                <input
                  type="text"
                  id="linkedin"
                  name="redesSociales"
                  value={datos.redesSociales || ''}
                  onChange={handleInputChange}
                  className={`form-control ${isFieldEmpty(datos.redesSociales) ? 'form-control-no-respondido' : 'form-control'}`} />
              </div></div>
              <div className='columna-7'><div className='form-group'>
                  <label htmlFor="personalidad" className="label-negrita">Respuesta formulario de personalidad</label>
                  <label className='label-gris'>https://www.humanmetrics.com/personalidad/test</label>
                  <select
                  className={`form-control ${isFieldEmpty(datosForm.id_personalidad) ? 'form-control-no-respondido' : 'form-control'}`}
                  name='id_personalidad'
                  value={datosForm.id_personalidad || ''}
                  onChange={(event) => handleDropdownChange(event)}
                >
                  <option value="">Selecciona una opción:</option>
                  {datosPersonalidad
                    .slice()
                    .sort((a, b) => a.personalidad.localeCompare(b.personalidad)) // Ordenar alfabéticamente
                    .map(personalidad => (
                      <option key={personalidad.id} value={personalidad.id}>
                        {personalidad.personalidad}
                      </option>
                    ))}
                </select>
                </div>
                </div>
                </div>
                <div className='form-group-brief'>
                  <label htmlFor="brief" className="label-negrita">Realiza un pequeño brief sobre ti </label>
                  <label className='label-gris'>Máximo 350 caracteres</label>
                  <textarea
                    id="brief"
                    name="brief"
                    value={datos.brief || ''}
                    onChange={handleInputChange}
                    placeholder='[carrera] de la Universidad [nombre de la universidad], con más de [años] de experiencia en áreas [indicar áreas de experiencia].
                    Tengo la capacidad de [indicar capacidades y habilidades] y los siguientes logros en mi experiencia laboral [indicar logros].
                    Además, me interesa desarrollar [indicar que le interesa desarrollar] y otros intereses como [indicar otros intereses].'
                    className={`form-control-brief ${isFieldEmpty(datos.brief) ? 'form-control-brief-no-respondido' : 'form-control-brief'}`} 
                    maxLength="350"/>
                </div>
                <div className='form-group-brief'>
                  <label htmlFor="brief" className="label-negrita">Sube tu CV:</label>
                  <input type='file' id='pdfFile' name='pdfFile' accept='.pdf' onChange={handleFileChange} className='margin-left'/>
                  <button className='button-cv' onClick={handleFile}>Enviar</button>
                </div>
                <div className='esconder'>
                <div className='form-group margin-left'>
                <label htmlFor="brief" className="label-negrita"><input
                  type="checkbox"
                  checked={datosForm.declaracion}
                  name='declaracion'
                  onChange={handleCheckboxChange}
                  />Declaro que los datos ingresados son verídicos</label>
                </div>
                </div></>
                
            )}
            {currentStep===8 &&(
              <>
              <div className='columna-responsive'>
                <div className='form-group'>
                  <label className='label-negrita'>Tiene algún contacto para validar la información</label>
                  <select
                    name="contacto1"
                    className='form-control'
                    onChange={handleAdicional}>
                    <option value=''>Selecciona una opción:</option>
                    <option value=''>No</option>
                    <option value='1'>Sí</option>
                  </select>
                </div>
                {mostrarContacto1 && (
                  <>
                    <div className='form-group'>
                      <label className="label-negrita">Nombre Completo</label>
                      <input
                        type="text"
                        id="contacto_nombre1"
                        name="contacto_nombre1"
                        onChange={handleContactos}
                        className="form-control" />
                    </div>
                    <div className='form-group'>
                      <label className="label-negrita">Celular</label>
                      <input
                        type="text"
                        id="contacto_celular1"
                        name="contacto_celular1"
                        onChange={handleContactos}
                        className="form-control" />
                    </div>
                    <div className='form-group'>
                      <label className="label-negrita">Mail</label>
                      <input
                        type="text"
                        id="contacto_mail1"
                        name="contacto_mail1"
                        onChange={handleContactos}
                        className="form-control" />
                    </div>
                  </>
                )}
              </div>
            
              <div className='columnas margin-columnas-top'>
                <div className='columna-izquierda'>
                  <label className='label-negrita'>Tiene algún contacto para validar la información</label>
                  <select
                    name="contacto1"
                    className='form-control'
                    onChange={handleAdicional}>
                    <option value=''>Selecciona una opcion:</option>
                    <option value=''>No</option>
                    <option value='1'>Si</option>
                  </select>
                {mostrarContacto1 && (
                  <><div className='form-group'>
                      <label className="label-negrita">Nombre Completo</label>
                      <input
                        type="text"
                        id="contacto_nombre1"
                        name="contacto_nombre1"
                        onChange={handleContactos}
                        className="form-control" />
                    </div><div className='form-group'>
                        <label className="label-negrita">Celular</label>
                        <input
                          type="text"
                          id="contacto_celular1"
                          name="contacto_celular1"
                          onChange={handleContactos}
                          className="form-control" />
                      </div></>
                )
                }
                </div>
                  {mostrarContacto1 && (
                      <div className='form-group'>
                        <label className="label-negrita">Mail</label>
                        <input
                          type="text"
                          id="contacto_mail1"
                          name="contacto_mail1"
                          onChange={handleContactos}
                          className="form-control" />
                      </div>
                
                  )}
              </div></>
            )}
            {currentStep===9 &&(
              <>
              <div className='columna-responsive'>
              <div className='form-group'>
                <label className='label-negrita'>Tiene otro contacto para validar la información</label>
                <select
                  name="contacto2"
                  className='form-control'
                  onChange={handleAdicional}>
                  <option value=''>Selecciona una opción:</option>
                  <option value=''>No</option>
                  <option value='1'>Sí</option>
                </select>
              </div>
              {mostrarContacto2 && (
                <>
                  <div className='form-group'>
                    <label className="label-negrita">Nombre Completo</label>
                    <input
                      type="text"
                      id="contacto_nombre2"
                      name="contacto_nombre2"
                      onChange={handleContactos}
                      className="form-control" />
                  </div>
                  <div className='form-group'>
                    <label className="label-negrita">Celular</label>
                    <input
                      type="text"
                      id="contacto_celular2"
                      name="contacto_celular2"
                      onChange={handleContactos}
                      className="form-control" />
                  </div>
                  <div className='form-group'>
                    <label className="label-negrita">Mail</label>
                    <input
                      type="text"
                      id="contacto_mail2"
                      name="contacto_mail2"
                      onChange={handleContactos}
                      className="form-control" />
                  </div>
                </>
              )}
            </div>
          
              <div className='columnas margin-columnas-top'>
                <div className='columna-izquierda'>
                <label className='label-negrita'>Tiene otro contacto para validar la información</label>
                  <select
                    name="contacto2"
                    className='form-control'
                    onChange={handleAdicional}>
                    <option value=''>Selecciona una opcion:</option>
                    <option value=''>No</option>
                    <option value='1'>Si</option>
                  </select>
                {mostrarContacto2 && (
                  <><div className='form-group'>
                      <label className="label-negrita">Nombre Completo</label>
                      <input
                        type="text"
                        id="contacto_nombre2"
                        name="contacto_nombre2"
                        onChange={handleContactos}
                        className="form-control" />
                    </div><div className='form-group'>
                        <label className="label-negrita">Celular</label>
                        <input
                          type="text"
                          id="contacto_celular2"
                          name="contacto_celular2"
                          onChange={handleContactos}
                          className="form-control" />
                      </div></>
                )
                }
                </div>
                <div className='columna'>
                {mostrarContacto2 && (
                      <div className='form-group'>
                        <label className="label-negrita">Mail</label>
                        <input
                          type="text"
                          id="contacto_mail2"
                          name="contacto_mail2"
                          onChange={handleContactos}
                          className="form-control" />
                      </div>
                
                  )}
                </div>
                
              </div></>
            )}
            <div className='botones'>
              {currentStep === 1 && (
                <button type="button" onClick={handleNext} className='siguiente-button'>Siguiente</button>
              )}

              {currentStep === 9 && (
                <>
                  <button type="button" onClick={handlePrev} className='atras-button'>Atras</button>
                  <button type="button" onClick={handleSubmit} className='siguiente-button'>Finalizar</button>
                </>
              )}

              {currentStep !== 1 && currentStep !== 9 && (
                <>
                  <button type="button" onClick={handlePrev} className='atras-button'>Atras</button>
                  <button type="button" onClick={handleNext} className='siguiente-button'>Siguiente</button>
                </>
              )}
            </div>

          </form>
        </div>
      </Modal>

    </div></>
);
}



export default Perfil;